import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import {
  Container,
  TextField,
  Grid,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { Autocomplete } from "@mui/material";
import loadingSpinner from "src/images/loading-spinner.gif";
// import Typography from "src/theme/typography";
import { getLookUpDataService } from 'src/_services/lookUp';

const PREFIX = 'AdvanceSearchForm';

const classes = {
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  buttonProgress: `${PREFIX}-buttonProgress`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.container}`]: {
    justifyContent: "center",
    justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginTop: '10px',
    fontWeight: 'bold',
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  }
}));

// console.log(getChannelPartner())
const renderCustomAutoComplete = (
  value,
  name,
  setFieldValue,
  options,
  label
) => {
  return (
    <Autocomplete
      value={value}
      fullWidth
      onChange={(event, newValue) => {
        setFieldValue(name, newValue);
      }}
      disableClearable
      getOptionLabel={(option) => option?.label }
      size="small"
      options={options}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={label} />
      )}
    />
  );
};


const allItem =[{
  id: 0,
  label: "All",
}]
function AdvanceSearchForm(props) {

  const [loading, setLoading] = React.useState(false);
  const [channelPartner, setChannelPartner] = React.useState([]);
  useEffect(() => {
    getLookUpDataService.getLookUpUser("SALES,OPERATION").then((res) => {
      let item = res.data.map((item) => {
        return {id:item.id,label:item.user_name};
      });
      setChannelPartner(allItem.concat(item));
    });
  }, []);
  
  const userType = [
    {
      id: 0,
      label: "All",
    },
    {
      id: 1,
      label: "Individual",
    },
  
    {
      id: 2,
      label: "Company",
    },
  ];

  const kycStatus = [
    {
      id: 'All',
      label: "All",
    },
    {
      id: 'Pending',
      label: "Pending",
    },
    {
      id: 'In Progress',
      label: "In Progress",
    },
    {
      id: 'Approved',
      label: "Completed",
    },
  ];

  return (
    <StyledContainer>
      <Formik
        initialValues={{
          rmName: allItem[0],
          kycStatus: allItem[0],
          userType: allItem[0],
        //   rmNameAnd: "AND",
        //   userTypeAnd:"AND",
        }}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <Typography className={classes.label}>
                    User Type
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  {renderCustomAutoComplete(
                    values.userType,
                    "userType",
                    setFieldValue,
                    userType,
                    "Select User Type"
                  )}
                  <br/>
                  {/* {renderRadioGroup(
                    values.userTypeAnd,
                    setFieldValue,
                    "userTypeAnd"
                  )} */}
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.label}>
                    RM Name
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  {renderCustomAutoComplete(
                    values.rmName,
                    "rmName",
                    setFieldValue,
                    channelPartner,
                    "Select RM Name"
                  )}
                  <br/>
                {/* {renderRadioGroup(
                    values.rmNameAnd,
                    setFieldValue,
                    "rmNameAnd"
                  )} */}
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.label}>
                    KYC Status
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  {renderCustomAutoComplete(
                    values.kycStatus,
                    "kycStatus",
                    setFieldValue,
                    kycStatus,
                    "Select KYC Status"
                  )}
                </Grid>
              </Grid>

              <Button
                color="primary"
                disabled={loading}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                onClick={() => {
                  setLoading(true);
                  props.formSubmit(values);
                }}
                variant="contained"
              >
                {loading === false ? (
                  "Search"
                ) : (
                  <>
                    <img
                      src={loadingSpinner}
                      alt="Logo"
                      className={classes.buttonProgress}
                    />{" "}
                    Search{" "}
                  </>
                )}
              </Button>
            </Container>
          </form>
        )}
      </Formik>
    </StyledContainer>
  );
}

export default AdvanceSearchForm;
