import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import {  useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { connect } from 'react-redux';
import { setToken, loginSuccess} from 'src/store/bugs'
import Page from "src/components/Page";
import {
  Box,
  Button,
  Container,
  TextField,
  Card,
  Grid,
  Typography,
} from "@mui/material";

import SnackBar from "src/components/SnackBar/SnackBar"
import { loginService } from 'src/_services/login'
import Cookies from 'universal-cookie'
import { RegisterService } from "src/_services/register";
import { RecaptchaVerifier, signInWithPhoneNumber,getAuth } from 'firebase/auth';
import DisableRightClick from "src/_helpers/DisableRightClick";
import { initializeApp,getApps } from "firebase/app";

const PREFIX = 'VerifyOtp';

const classes = {
  root: `${PREFIX}-root`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  pageLayout: `${PREFIX}-pageLayout`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {

  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: 10,
    marginBottom: "10%",
  },

  [`& .${classes.pageLayout}`]: {
    height: 'calc(100vh - 128px)',
    padding: '0px !important'
  }
}));

const cookies = new Cookies();


const OtpPage = (props) => {

  const navigate = useNavigate();
  let location = useLocation();  
  const isDisabled = true
  const [open, setOpen] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [severity, setSeverity] = React.useState("");
  const lableName = "OTP Verification"
  const [resendOtpTime, setResendOtpTime] = React.useState(20);
  const [mobileNumber , setMobileNumber] = useState("")
  const [userData , setUserData] = useState(null)
  const [submit,setSubmit] = useState(true);
  let auth;
  useEffect(() => {
    const resendOtpSec = setTimeout(() => {
      if (resendOtpTime > 0) {
        setResendOtpTime(resendOtpTime - 1);
      }
      else {
        clearTimeout(resendOtpSec);
      }
    }, 1000);
  }, [resendOtpTime]);
  

  useEffect(()=>{
    setMobileNumber(location.state.mobile)
    if(userData){     
      if(userData.default_role_code==="ADMIN" || userData.default_role_code==="SALES" || userData.default_role_code==="ADMIN_VIEWER" || userData.default_role_code==="OPERATION"){
       navigate("/app/dashboard", { replace: true })
      }
      else if(userData.default_role_code==="INVESTER_CLIENT" || userData.default_role_code==="CHANNEL_PARTNER"){
        navigate("/app/investor-dashboard", { replace: true })
      }
      else{
        alert("Access Denied")
      }
    }
  },[userData])

  useEffect(()=>{
    auth = JSON.parse(location.state.auth)
  },[])

  
  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha',{
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      }
    }, auth);
  }
  

  async function initializeFirebae(){
    try {
      let app;
      let firebaseEnv = process.env.REACT_APP_ADMIN_CLIENT.replace(/\\/g, '');
      let firebaseEnvFormated = firebaseEnv.replace(/""/g, '');
      let firebaseEnvFormatedObject = JSON.parse(firebaseEnvFormated)
        if(getApps().length === 0 ){
        app = initializeApp(firebaseEnvFormatedObject.REACT_APP_ADMIN_CLIENT);
      }
      auth = getAuth(app);
    } catch (error) {
     console.log(error);
    } 
   }

  const handleOtp =async () => {
    await initializeFirebae()
    if (location.state.mobile ) {
      setMobileNumber(location.state.mobile)
      setResendOtpTime(30)
      
      loginService.mobileAuthentication(mobileNumber)
      .then( res => {
        if(res.status === 200){
          generateRecaptcha();
          let appVerifier = window.recaptchaVerifier;
          signInWithPhoneNumber(auth, mobileNumber, appVerifier)
            .then((confirmationResult) => {
               window.confirmationResult = confirmationResult;
              }).catch((error) => {
                // Error; SMS not sent
                console.log(error);
              });  
          setSeverity("success")
          setSnackbarTitle("Otp sent")
          setOpen(true);
        }
      })
    }
    else{
      if(location.state.type === "register"){
        setResendOtpTime(30)
        RegisterService.registerMobileNumber(location.state.phoneNumber).then(res => {
          if(res){
            if(res.status === 200){
              setSeverity("success")
              setSnackbarTitle("Otp sent")
              setOpen(true);
            }
          } else {
            setSeverity("error")
            setSnackbarTitle("Unable to connect the server, Please try again")
            setOpen(true);
          }
        })
      }
    }
  }

 
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
 
  const setUserInfo = ()=>{
    loginService.getLoggedInUserInfo().then(async res => {
      if(res){
        if(res.status===200){
          const auth = {loggedIn : true, user:res.data}
          props.loginSuccess({auth:auth})
          setUserData(res.data);
        }
      }
      else{
        setSeverity("error")
        setSnackbarTitle("Unable to connect the server, please try again")
        setOpen(true);
      }
    })
  }

  const handleOnSubmit = (values) => {
    if (location.state.mobile ) {
      let confirmationResult = window.confirmationResult;
      confirmationResult?.confirm(values.otp).then((result,error) => {
    if(result){
      loginService.verifyMobileOtp(location.state.mobile || null, values.otp).then(res => {         
          if(res){
            setSubmit(false)
            if (res.status === 200) {              
              if (res.data.message === "OTP_VERIFIED") {
                props.setToken({ token: res.data.Jwttoken.token });                           
                cookies.set('token', res.data.Jwttoken.token, { path: '/', expires: new Date(new Date().getTime() + 60 * 60 * 1000 * 24 * 180) });
                setUserInfo();
                setSeverity("success")
                setSnackbarTitle("Otp Verified Successfully!")
                setOpen(true); 
              }
            }
            else if(res.status === 400){
              if(res.data.message==="INVALID_OTP"){
                setSeverity("error")
                setSnackbarTitle("Invalid OTP")
                setOpen(true);
              }
            }
            else if (res.status === 401) {
              if(res.data.message==="ACCOUNT_BLOCKED"){
                setSeverity("error")
                setSnackbarTitle("Your account is blocked")
                setOpen(true);
              }
            }
            else {
            }
          }
        })
      }
    }).catch(()=> {
      setSubmit(false)
      setSeverity("error")
      setSnackbarTitle("Invalid OTP")
      setOpen(true);
    })
    }
    else {
      if(location.state.type === "register"){
        let verifyRegisterData = "";
        if(location.state.userType === "INDIVIDUAL"){
          verifyRegisterData = {
            user_type:location.state.userType,
            first_name:location.state.firstName,
            last_name:location.state.lastName,
            email:location.state.email,
            mobile_number:location.state.phoneNumber,
            otp:values.otp,
            is_accredit:location.state.isInvestor ,
            country_code:location.state.countryCode
          };
        }
        else {
          verifyRegisterData = {
            user_type:location.state.userType,
            company_name:location.state.companyName,
            company_registration_no:location.state.RegistrationNumber,
            gstin:location.state.gstin,
            email:location.state.email,
            mobile_number:location.state.phoneNumber,
            otp:values.otp,
            is_accredit:location.state.isInvestorCompany ,
            country_code:location.state.countryCode
          };
        }

        RegisterService.verifyRegisterOTP(verifyRegisterData).then(res => {
          if(res){
            setSubmit(false)
            if (res.status === 200) {
              if (res.data.message === "OTP_VERIFIED") {
                props.setToken({ token: res.data.Jwttoken.token });                           
                cookies.set('token', res.data.Jwttoken.token, { path: '/', expires: new Date(new Date().getTime() + 60 * 60 * 1000 * 24 * 180) });
                setUserInfo();
                setSeverity("success")
                setSnackbarTitle("Otp Verified Successfully!")
                setOpen(true);
                setTimeout(() => navigate("/app/investor-dashboard", { replace: true }), 1000)
              }
            }
            else if(res.status === 400){
              if(res.data.message==="INVALID_OTP"){
                setSeverity("error")
                setSnackbarTitle("Invalid OTP")
                setOpen(true);
              }
            }
            else if (res.status === 401) {
              if(res.data.message==="ACCOUNT_BLOCKED"){
                setSeverity("error")
                setSnackbarTitle("Your account is blocked")
                setOpen(true);
              }
            }
            else {
            }
          }
        })
      }
    }
  }

  return (
    (<Root>
      <Page addClass={classes.pageLayout} title="Unlistedkar | Login">
        <DisableRightClick>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          <Container maxWidth="sm">
            <Formik
              initialValues={{
                otp: ""
              }}
              validationSchema={Yup.object().shape({
                // otp: Yup.string()
                // .matches(phoneRegex, "Invalid Otp")
                // .required("Enter an OTP sent to your mobile number"),
              })}
              onSubmit={(values) => {

                handleOnSubmit(values)
                setSubmit(true)
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container direction="column">
                    {/* <header>
                        <img src={logo} alt="logo" className={classes.imageSizes}/>
                </header> */}
                    <Card className={classes.cardBorderRadius}>
                      <Typography
                        className={classes.cardHeaderMargin}
                        variant="h5"
                      >
                        {lableName}
                      </Typography>
                      <Container className={classes.container}>
                        <Typography
                          color="primary"
                          gutterBottom
                          variant="h5"
                          className={classes.marginInputLabel}
                        >
                          Please Enter the OTP
                      </Typography>

                        <TextField
                          error={Boolean(
                            touched.otp && errors.otp
                          )}
                          helperText={touched.otp && errors.otp}
                          label="OTP"
                          name="otp"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          fullWidth
                          value={values.otp}
                          autoComplete='off'
                          variant="outlined"
                          labelWidth={0}
                          // onInput = {(e) =>{
                          //     e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,5)
                          // }}
                          inputProps={{ maxLength: 6 }}
                          onInput={(e) => { 
                            e.target.value = e.target.value.replace(/[^0-9]/g, '') 
                            if(e.target.value.length === 6){
                              setSubmit(false)
                            }
                          }}
                          floatingLabelText="To*"
                          floatingLabelFixed={true}
                        />
                        <br />
                        <Button
                          color="primary"
                          disabled={submit || values.otp.length < 6}
                          className={classes.popDownBtn}
                          size="medium"
                          type="submit"
                          variant="contained"
                        >
                          Verify
                      </Button>
                        <div className={classes.resendBtnMargin}>
                          {resendOtpTime > 0 ?
                            (<div>
                              <Button onClick={handleOtp}  color="primary" disabled={isDisabled}>Resend otp</Button>
                              <br />
                              <Typography className={classes.seconds} >{`${resendOtpTime} seconds`}</Typography></div>)
                            : <Button onClick={handleOtp} color="primary" disabled={!isDisabled}>Resend otp</Button>
                          }
                        </div>
                      </Container>
                    </Card>
                  </Grid>
                </form>
              )}
            </Formik>
            
          </Container>
        </Box>
      <div id="recaptcha"></div>
      </DisableRightClick>
      </Page>
      <SnackBar
        open={open}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />
    </Root>)
  );
};

const dispatchToProps = (dispatch) => ({
  setToken: (token) => dispatch(setToken(token)),
  loginSuccess: (auth) => dispatch(loginSuccess(auth)),
  
});

export default connect(null, dispatchToProps)(OtpPage);
