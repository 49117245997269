import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import {
  Container,
  TextField,
  Grid,
  Typography
} from "@mui/material";
import { Formik } from "formik";
import { Autocomplete } from "@mui/material";
import loadingSpinner from "src/images/loading-spinner.gif";
// import Typography from "src/theme/typography";
import { getLookUpDataService } from "src/_services/lookUp";
import moment from "moment";

const PREFIX = 'AdvanceSearchForm';

const classes = {
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  buttonProgress: `${PREFIX}-buttonProgress`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.container}`]: {
    justifyContent: "center",
    justifyItems: "center",
    marginTop: "5%",
  },

  [`& .${classes.label}`]: {
    marginTop: "10px",
    fontWeight: "bold",
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  }
}));



// console.log(getChannelPartner())
const renderCustomAutoComplete = (
  value,
  name,
  setFieldValue,
  options,
  label
) => {
  return (
    <Autocomplete
      value={value}
      fullWidth
      onChange={(event, newValue) => {
        setFieldValue(name, newValue);
      }}
      options={ options }
      disableClearable
      getOptionLabel={(option) => option?.label }
      size="small"
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={label} />
      )}
    />
  );
};


function AdvanceSearchForm(props) {

  const [loading, setLoading] = React.useState(false);
  const [channelPartner, setChannelPartner] = React.useState([]);

  let allItem = [{ id: "", label: "All" }]
  useEffect(() => {
    getLookUpDataService
      .getLookUpUser("CHANNEL_PARTNER,SALES,OPERATION,ADMIN")
      .then((res) => {
        let item = res.data.map((item) => {
          return { id: item.id,
            label: `${item.user_name} ( ${item.user_code.toUpperCase()} )`,
             };
        });
        setChannelPartner(allItem.concat(item));
      });
    

  }, []);

  const log_type = [
    {
      id: 1,
      label: "Users",
    },
    {
      id: 2,
      label: "Scrips",
    },
    {
      id: 3,
      label: "Orders",
    },
  ];
  const action_type = [
    {
      id: 1,
      label: "Create",
    },
    {
      id: 2,
      label: "Update",
    },
    {
      id: 3,
      label: "Delete",
    },
  ];

const values =props.advanceSearchForm ? props.advanceSearchForm :{
  from_date: "",
  channel_partner: allItem[0],
  to_date: `${moment(new Date()).format("YYYY-MM-DD")}`,
  log_type: allItem[0],
  action_type: allItem[0],
} 
  return (
    <StyledContainer>
      <Formik
        initialValues={values}
      >
        {({
          handleSubmit,
          isSubmitting,
          setFieldValue,
          values,
          handleChange,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} className={classes.container}>
              <Grid item xs={4}>
                <Typography className={classes.label}>Date Range</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="from_date"
                  label="From Date"
                  type="date"
                  name="from_date"
                  onChange={handleChange}
                  InputProps={{
                    inputProps: {
                      max: `${moment(values.to_date).format("YYYY-MM-DD")}`,
                    },
                  }}
                  value={values.from_date}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="to_date"
                  label="To date"
                  type="date"
                  name="to_date"
                  onChange={handleChange}
                  InputProps={{
                    inputProps: {
                      max: `${moment(new Date()).format("YYYY-MM-DD")}`,
                      min: `${moment(values.from_date).format("YYYY-MM-DD")}`,
                    },
                  }}
                  value={values.to_date}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.label}>
                  Internal RM
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {renderCustomAutoComplete(
                  values.channel_partner,
                  "channel_partner",
                  setFieldValue,
                  channelPartner,
                  "Select Internal RM"
                )}
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.label}>
                  Log Type
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {renderCustomAutoComplete(
                  values.log_type,
                  "log_type",
                  setFieldValue,
                  log_type,
                  "Select Log Type"
                )}
              </Grid>
           
              <Grid item xs={4}>
                <Typography className={classes.label}>Action Type</Typography>
              </Grid>
              <Grid item xs={8}>
                {renderCustomAutoComplete(
                  values.action_type,
                  "action_type",
                  setFieldValue,
                  action_type,
                  "Select action type "
                )}
              </Grid>
            
            
            </Grid>

            <Button
              color="primary"
              disabled={loading}
              className={classes.popDownBtn}
              size="medium"
              type="submit"
              onClick={() => {
                setLoading(true);
                props.formSubmit(values);
              }}
              variant="contained"
            >
              {loading === false ? (
                "Search"
              ) : (
                <>
                  <img
                    src={loadingSpinner}
                    alt="Logo"
                    className={classes.buttonProgress}
                  />{" "}
                  Search{" "}
                </>
              )}
            </Button>
          </form>
        )}
      </Formik>
    </StyledContainer>
  );
}

export default AdvanceSearchForm;
