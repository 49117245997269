import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Container, TextField, Grid } from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import loadingSpinner from "src/images/loading-spinner.gif";
import compareJSON from "src/components/comparision";

const PREFIX = "UpdateStatus";

const classes = {
  container: `${PREFIX}-container`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  buttonProgress: `${PREFIX}-buttonProgress`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.container}`]: {
    justifyContent: "center",
    justifyItems: "center",
    marginTop: "10%",
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  },
}));

function UpdateStatus({data,formSubmit}) {
  const [loading,setLoading] = useState(false)
  const initialData ={
    status: data.status ===2? "Pending": data.status ===1 ?"Verified":"Rejected",
    reason:data.reason
  }
  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialValues={initialData}
        validationSchema={Yup.object().shape({
          reason:Yup.string().when("status", {
            is: "Rejected",
            then: Yup.string().required("Required"),
          })
        })}
        onSubmit={(values) => {
          setLoading(true)
          let updatedValues = compareJSON(values,initialData)
          values={...values,updatedValues:JSON.stringify(updatedValues)}
          formSubmit(values);
        }}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Autocomplete
                    id="status"
                    value={values.status}
                    options={["Verified","Pending","Rejected"]}
                    onChange={(e, value) => {
                      if (value !== null) {
                        setFieldValue("status", value);
                        if(value !=='Rejected'){
                            setFieldValue("reason", '');
                        }
                      } else {
                        setFieldValue("status", "");
                      }
                    }}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="status"
                        label="Status*"
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
               {values.status ==='Rejected' ?
               ( <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                 <TextField
                 name="reason"
                 label="Reject Reason*"
                 fullWidth
                 value={values.reason}
                 onChange={handleChange}
                 error={Boolean(touched.reason && errors.reason)}
                 helperText={touched.reason && errors.reason}
                 variant="standard"
                 />
                </Grid>)
              : null}
              </Grid> 

              <Button
                color="primary"
                disabled={isSubmitting}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="contained"
              >
                {loading === false ? (
                  "Update"
                ) : (
                  <>
                    <img
                      alt="loader"
                      src={loadingSpinner}
                      className={classes.buttonProgress}
                    />{" "}
                    Update{" "}
                  </>
                )}
              </Button>
            </Container>
          </form>
        )}
      </Formik>
    </StyledContainer>
  );
}

export default UpdateStatus;
