import { Button, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,Table,TableCell,TableContainer,TableRow,TableBody,Paper,TableHead
} from "@mui/material";
import Page from "src/components/Page";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {  ThemeProvider } from "@mui/material/styles";
import MUIDataTable from "mui-datatables";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Divider from "@mui/material/Divider";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import NormalCard from "src/components/Cards/NormalCard";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
// import ImageIcon from '@mui/icons-material/ImageIcon';
import ImageIcon from "@mui/icons-material/Image";
import ClearIcon from "@mui/icons-material/Clear";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import VerifiedChecklistAndDetails from "./VerifiedChecklistAndDetails";
import Tooltip from "@mui/material/Tooltip";
import ModalCentered from "src/components/Modals/ModalCentered";
import SnackBar from "src/components/SnackBar/SnackBar";
import CircularLoading from "src/components/Loading/Loading";
import { useParams } from "react-router-dom";
import { serviceOrderService } from "src/_services/serviceOrder";
import DocumentUploadForm from "./DocumentUploadForm";
import UpdateStatusForm from "./UpdateStatusForm";
import moment from "moment";
import UpdateAssignForm from "./UpdateAssignForm";
import AlertModal from "src/components/AlertModal/AlertModal";
import MappingServiceOrderForm from "./MappingServiceOrderForm";
import { connect } from "react-redux";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import NavigationArrowIconButton from "../../components/ButtonComponents/NavigationArrowIconButton";
// import NavigationArrowIconButton from "../../components/NavigationIconButton/NavigationArrowIconButton";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
// import ReactHtmlParser from "react-html-parser";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DashboardLogs from "./ServiceOrderLogs";

import { Formik, Field } from "formik";
import * as Yup from "yup";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip1 from "../../components/Tooltip/tooltip";
import ImageRotate from "../Profile/Rotate";
import { getLookUpDataService } from "src/_services/lookUp";
import MailTemplate from "./MailTemplate";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EscrowCard from "./EscrowCard";
import GetMuiTheme from "src/components/theme";
// import EditIcon from '@mui/icons-material/Edit';
import CommissionStatus from "./CommissionStatus";
import Edit from "@mui/icons-material/Edit";
import CommissionStatusForIndividual from "./CommissionStatusForIndividual.jsx";
import SalesOrdeUpdate from "./SalesOrderUpdate";
import GenerateDealTerm from "./GenerateDealTerm";
import compareJSON from "src/components/comparision";
const PREFIX = 'ServiceOrderDetails';


const classes = {
  root: `${PREFIX}-root`,
  hdrlblbtn: `${PREFIX}-hdrlblbtn`,
  induvidual: `${PREFIX}-induvidual`,
  labelname: `${PREFIX}-labelname`,
  details: `${PREFIX}-details`,
  tooltip: `${PREFIX}-tooltip`,
  formgrgroup: `${PREFIX}-formgrgroup`,
  btndocuments: `${PREFIX}-btndocuments`,
  btnprimary: `${PREFIX}-btnprimary`,
  iconpdf: `${PREFIX}-iconpdf`,
  editbutton: `${PREFIX}-editbutton`,
  pricelbl: `${PREFIX}-pricelbl`,
  remark: `${PREFIX}-remark`,
  table: `${PREFIX}-table`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  verified: `${PREFIX}-verified`,
  verified_item: `${PREFIX}-verified_item`,
  check: `${PREFIX}-check`,
  download: `${PREFIX}-download`,
  unCheck: `${PREFIX}-unCheck`,
  shortdetails: `${PREFIX}-shortdetails`,
  backButton: `${PREFIX}-backButton`,
  instructions: `${PREFIX}-instructions`,
  pricebar: `${PREFIX}-pricebar`,
  backdrop: `${PREFIX}-backdrop`,
  pricelabel: `${PREFIX}-pricelabel`,
  btnright: `${PREFIX}-btnright`,
  stepname: `${PREFIX}-stepname`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    // paddingBottom: theme.spacing(10),
    // paddingTop: theme.spacing(2),
    width: "100%",
    padding: "1rem",
  },

  [`& .${classes.hdrlblbtn}`]: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(0),
    alignItems: "center",
    marginBottom: "1%",
  },

  [`& .${classes.induvidual}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.labelname}`]: {
    [theme.breakpoints.up(576)]: {
      width: "35%",
    },
    width: "50%",
    textAlign: "end",
    fontWeight: "500",
  },

  [`& .${classes.details}`]: {
    // width: "68%",
    marginLeft: theme.spacing(2),
  },

  [`& .${classes.tooltip}`]: {
    cursor: "copy",
  },

  [`& .${classes.formgrgroup}`]: {
    display: "flex",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.btndocuments}`]: {
    display: "flex",
    alignItems: "center",
    marginLeft: "16px",
  },

  [`& .${classes.btnprimary}`]: {
    borderRadius: "12px 12px 12px 0px",
    marginRight: theme.spacing(2),
  },

  [`& .${classes.iconpdf}`]: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(-1),
  },

  [`& .${classes.editbutton}`]: {
    borderRadius: "14px 14px 0 0",
    boxShadow: "none",
    textTransform: "capitalize",
    fontSize: "0.9rem",
    lineHeight: "1.3",
  },

  [`& .${classes.pricelbl}`]: {
    textAlign: " end",
    padding: "8px 18px 0px 9px",
    fontSize: "1rem",
    color: "rgb(49, 75, 143)",
    fontWeight: " 500",
  },

  [`& .${classes.remark}`]: {
    padding: theme.spacing(2),
    textAlign: "justify",
    "& table,th,td": {
      border: "1px solid #ddd",
    },
    "& th": {
      color: "#ffffff",
      padding: "6px",
      fontWeight: 500,
      lineHeight: "1rem",
      whiteSpace: "nowrap",
      backgroundColor: "#6C6B74",
    },
    "& table": {
      margin: "1rem 0",
      width: "100%",
      textAlign: "left",
      borderCollapse: "collapse",

      borderSpacing: 0,
    },
    "& th,td": {
      padding: 8,
      whiteSpace: "nowrap",
    },
  },

  [`& .${classes.table}`]: {
    "& table,th,td": {
      border: "1px solid #ddd",
    },
    "& th": {
      color: "#ffffff",
      padding: "6px",
      fontWeight: 500,
      lineHeight: "1rem",
      whiteSpace: "nowrap",
      backgroundColor: "#6C6B74",
    },
    "& table": {
      margin: "1rem 0",
      width: "100%",
      textAlign: "left",
      borderCollapse: "collapse",

      borderSpacing: 0,
    },
    "& th,td": {
      padding: 8,
      whiteSpace: "nowrap",
    },
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 16px 16px 0",
    padding: "10px",
    textTransform: "none",
  },

  [`& .${classes.verified}`]: {
    display: "flex",
    flexWrap: "wrap",
  },

  [`& .${classes.verified_item}`]: {
    margin: "16px 16px -4px 16px",
  },

  [`& .${classes.check}`]: {
    fontSize: "20px",
    marginBottom: theme.spacing(-0.5),
    color: "#4caf50",
  },

  [`& .${classes.download}`]: {
    fontSize: "20px",
    marginBottom: theme.spacing(-0.5),
    // color: "#4caf50",
  },

  [`& .${classes.unCheck}`]: {
    fontSize: "20px",
    marginBottom: theme.spacing(-0.5),
    color: "#f44336",
  },

  [`& .${classes.shortdetails}`]: {
    paddingLeft: theme.spacing(3.1),
    color: "#8F8F8F",
    fontSize: "12px",
  },

  [`& .${classes.backButton}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.instructions}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  [`& .${classes.stepname}`]: {
    fontSize: 8,
  },
  

  [`& .${classes.pricebar}`]: {
    display: "flex",
    paddingTop: theme.spacing(1),
    fontWeight: "500",
    color: "rgb(49, 75, 143)",
    marginBottom: theme.spacing(-1),
  },

  [`& .${classes.backdrop}`]: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  [`& .${classes.pricelabel}`]: {
    [theme.breakpoints.down(960)]: {
      width: "50%",
      textAlign: "left",
    },
    width: "80%",
    textAlign: "end",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },

  [`& .${classes.btnright}`]: {
    textAlign: "end",
  }
}));

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
  // eslint-disable-line
  return <NavLink to={props.to} {...props} />; // eslint-disable-line
});

const ServiceOrderDetails = (props) => {

  const navigate = useNavigate();
  let id = useParams();
  let [useParamsId, setUserParamsId] = React.useState(id.id);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [requiredQuantity, setRequiredQuantity] = useState("");
  const [titleModal, setTitleModal] = useState("");
  const [severity, setSeverity] = React.useState("");
  const [loading, setLoading] = useState(true);
  let [serviceOrderData, setServiceOrderData] = useState({});
  let [securityData, setSecurityData] = React.useState([]);
  const [documentData, setDocumentData] = React.useState([]);
  const [securityId, SetSecurityId] = React.useState("");
  let [modalType, setModalType] = React.useState("");
  let [imageDialog, setImageDialog] = React.useState(false);
  let [isDisabled, setIsDisabled] = React.useState(false);
  let [salesEditDialog, setSalesEditDialog] = useState(false);
  const [isSignedFileExist,setIsSignedFileExist] =  useState(false);
  const [commissionStatusDialog,setCommissionStatusDialog] = useState({
    open:false,
    item:null
  })
  let [completeOrderModel, setCompleteOrderModel] = React.useState(false);
  let [initialData, setInitialData] = React.useState({
    remarks: "",
  });

  const role = props.authReducer.auth
      ? props.authReducer.auth.user !== null &&
          props.authReducer.auth.user.default_role_code
      : null
  let [activeStep, setActiveStep] = React.useState(0);
  const [verifyModalMode, setVerifyModalMode] = React.useState("");
  const [verifiedDetailsData, setVerifiedDetailsData] = React.useState({
    is_client_details_verified: {
      value: null,
      last_updated_user: null,
      last_updated_datetime: null,
    },
    is_demat_account_verified: {
      value: null,
      last_updated_user: null,
      last_updated_datetime: null,
    },
    is_security_recieved_or_transferred_verified: {
      value: null,
      last_updated_user: null,
      last_updated_datetime: null,
    },
    security_transaction_id: null,
    is_payment_recieved_or_transferred_verified: {
      value: null,
      last_updated_user: null,
      last_updated_datetime: null,
    },
    payment_transaction_id: null,
    is_signed_deal_term_uploaded_verified: {
      value: null,
      last_updated_user: null,
      last_updated_datetime: null,
    },
    is_shared_credit_confirmed_verified: {
      value: null,
      last_updated_user: null,
      last_updated_datetime: null,
    },
  });
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [documentID, setDocumentID] = useState();
  const [dealTermFileData, setDealTermFileData] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [serviceOrderType, setServiceOrderType] = useState("");
  // const [mappedStatus, setMappedStatus] = useState(false);
  // const [invoice, setInvoice] = React.useState();
  const [nseMailHistory, setNseMailHistory] = React.useState([]);
  const [template, setTemplate] = React.useState(null);
  const [escrowResponse, setEscrowResponse] = useState([]);

  const [isVerifiedDetails, setIsVerifiedDetails] = useState(false);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [url, setUrl] = useState({
    url: "",
    isPdf: false,
  });
  const [show, setShow] = useState(false);

  const [copy, setCopy] = useState(false);
  const stepName = ["INACTIVE", "Deal term", "Share transfer", "Completed"];

  const stepNameOffline = [
    "INACTIVE",
    "ACTIVE",
    "PROCESSING",
    "COMPLETED",
    "CLOSED",
  ];
  const stepNamePartner = [
    "INACTIVE",
    "CANCELLED",
    "ONHOLD",
    "PREPROCESSING",
    "ACTIVE",
    "PROCESSING",
    "COMPLETED",
    "CLOSED",
  ];
  const NSEStatus = [
    "INACTIVE",
    "ACTIVE",
    "PROCESSING",
    "STAGE_ONE_PROCESSING",
    "STAGE_ONE_PROCESSED",
    "STAGE_TWO_PROCESSING",
    "STAGE_TWO_PROCESSED",
    "COMPLETED",
    "CLOSED",
  ];

  const location = useLocation();

  let totalConsolidatedPrice = 0;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };
  
  const handleDocumentData = () => {
    setModalType("Documents");
    setTitleModal("Upload Documents");
    setSeverity("success");
    setSnackbarTitle("Documents are uploaded successfully");
    setOpenModal(true);
  };

  const generateDealTerm = () => {
    setModalType("Generate");
    setTitleModal("Generate Deal Term");
    setSeverity("success");
    setSnackbarTitle("Downloaded");
    setOpenModal(true);
  };

  const handleVerificationModal = () => {
    setVerifyModalMode("create");
    setModalType("verification");
    setTitleModal("Verified Checklist and Details");
    setSeverity("success");
    setSnackbarTitle("Verified Checklist is Checked");
    setOpenModal(true);
  };

  const handleEditVerificationModal = () => {
    setVerifyModalMode("edit");
    setModalType("verification");
    setTitleModal("Verified Checklist and Details");
    setSeverity("success");
    setSnackbarTitle("Verified Checklist is Checked");
    setOpenModal(true);
  };

  // const handleCommission = () => {
  //   setModalType("Commission");
  //   setTitleModal("Commission Paid Status");
  //   setSeverity("success");
  //   setOpenModal(true);
  // };

  const handleMails = (item) => {
    setModalType("mail");
    setTitleModal("Send Mail");
    setSeverity("success");
    setSnackbarTitle("Mail sent successfully");
    setOpenModal(true);
    setTemplate({
      mail_type: {
        id: item.mail_template_id,
        lable: item.subject,
      },
      subject: item.subject,
      email_template: item.email_template,
      file: "",
    });
  };

  const handleStatusModal = () => {
    setModalType("updateStatus");
    setTitleModal("Update Status");
    setSeverity("success");
    // setSnackbarTitle("Service Order Status Updated Successfully");
    setOpenEditModal(true);
  };

  const handleAssignModal = () => {
    setModalType("updateAssign");
    setTitleModal("Update Assign");
    setSeverity("success");
    // setSnackbarTitle(" Assign Updated Successfully");
    setOpenEditModal(true);
  };

  const handleMappingServiceOrderModal = (securityId) => {
    setModalType("mappingServiceOrder");
    SetSecurityId(securityId);
    setTitleModal("Mapping Order");
    setSeverity("success");
    setSnackbarTitle(" Assign Updated Successfully");
    setOpenEditModal(true);
  };

  const generateDealTermForFacilitator=((values)=>{
    if(!values.discloseStatus){
      downloadFile(dealTermFileData)
    }else{
      const data={
        dealtermType:parseInt(values.type),
        canDiscloseClientDetails:!values.discloseStatus,
        Id:parseInt(useParamsId)
      }
      serviceOrderService.generateDealTerm(data).then((res)=>{
        if (res.status === 200) {
          const file = new Blob([res.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          downloadFile(fileURL)
        } else 
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true);
            setSnackBarOpen(true)
            setOpenModal(false);
            setSeverity('error')
            setSnackbarTitle('Download failed')
  
          }
        
      })
    }
  })

  const downloadFile =((fileURL)=>{
   
    const link = document.createElement('a');
    link.href = fileURL
    link.download = `Dealterm_${serviceOrderData.service_id}.pdf`;
    link.click();
    setOpenModal(false);
    setLoading(false)
    setSnackBarOpen(true)
    setSeverity('success')
    setSnackbarTitle('Download success')
  })

  const getNSEMailHistory = () => {
    serviceOrderService
      .getNseMailHistory(useParamsId)
      .then((res) => {
        if (res) {
          setLoading(false);
          if (res.status === 200) {
            setNseMailHistory(res.data.data);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const submitCommissionStatus = (values) => {
    try {
      serviceOrderService.updateCommissionStatus(values).then((res) => {
        setTitleModal();
        if (res.status === 200) {
          setOpenModal(false);
          getOrderBasicInfo();
          setSeverity("success");
          setSnackBarOpen(true);
          setSnackbarTitle("Commission status updated");
        } else {
          setOpenModal(false);
          setSeverity("error");
          setSnackBarOpen(true);
          setSnackbarTitle("Failed to update");
        }
      });
    } catch (error) {
      setOpenModal(false);
      setSeverity("error");
      setSnackBarOpen(true);
      setSnackbarTitle("Failed to update");
    }
  };

  const getSecurityData = (id) => {
    serviceOrderService
      .getSecurityByID(id)
      .then((res) => {
        if (res) {
          setLoading(false);
          if (res.status === 200) {
            setSecurityData(res.data.sort((a, b) => a.security_name.localeCompare(b.security_name)));
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getOrderBasicInfo = async () => {
    setOpen(true);
    await serviceOrderService
      .getServiceOrderByID(useParamsId)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setServiceOrderData(res.data);
            // setApprove(res.data.ops_verifed_signature == null ? false : res.data.ops_verifed_signature == 1 ? true : false)
            setServiceOrderType(res.data.order_type_code);
            setInitialData({
              remarks: res.data.remarks,
            });
            if (res.data.is_nse_trade) {
              getNSEMailHistory();
            }
            if (res.data.is_purchased_online) {
              serviceOrderService
                .getEscrowDetailsOfServiceOrder(res.data.id)
                .then((res) => {
                  setEscrowResponse(res.data.data);
                });
              // console.log(res.data.confirmed_verified, res.data.dealterm_uploaded, res.data.user_signed_status, res.data.order_status_code);
              if (
                res.data.confirmed_verified === "false" &&
                res.data.dealterm_uploaded === "false" &&
                res.data.user_signed_status == null &&
                res.data.order_status_code !== "INACTIVE" &&
                res.data.order_status_code !== "COMPLETED" &&
                res.data.order_status_code !== "CLOSED"
              ) {
                res.data["order_status_new"] = "Deal term";
                res.data["order_status_new_display"] = "Deal Term Pending";
                // console.log("1");
              } else if (
                res.data.confirmed_verified === "false" &&
                res.data.dealterm_uploaded === "true" &&
                res.data.user_signed_status === "pending" &&
                res.data.order_status_code !== "INACTIVE" &&
                res.data.order_status_code !== "COMPLETED" &&
                res.data.order_status_code !== "CLOSED"
              ) {
                res.data["order_status_new"] = "Deal term";
                res.data["order_status_new_display"] = "Share Transfer Pending";
                // console.log("2");
              } else if (
                res.data.confirmed_verified === "false" &&
                res.data.dealterm_uploaded === "false" &&
                res.data.user_signed_status === "complete" &&
                res.data.order_status_code !== "INACTIVE" &&
                res.data.order_status_code !== "COMPLETED" &&
                res.data.order_status_code !== "CLOSED"
              ) {
                res.data["order_status_new"] = "Deal term";
                res.data["order_status_new_display"] = "Share Transfer Pending";
                // console.log("3");
              } else if (
                res.data.confirmed_verified === "false" &&
                res.data.dealterm_uploaded === "true" &&
                res.data.user_signed_status === "complete" &&
                res.data.order_status_code !== "INACTIVE" &&
                res.data.order_status_code !== "COMPLETED" &&
                res.data.order_status_code !== "CLOSED"
              ) {
                res.data["order_status_new"] = "Share transfer";
                res.data["order_status_new_display"] = "Share Transfer Pending";
                // console.log("4");
              } else if (
                res.data.confirmed_verified === "true" &&
                res.data.dealterm_uploaded === "true" &&
                res.data.user_signed_status === "complete" &&
                res.data.order_status_code !== "INACTIVE" &&
                res.data.order_status_code !== "COMPLETED" &&
                res.data.order_status_code !== "CLOSED"
              ) {
                res.data["order_status_new"] = "Share transfer";
                res.data["order_status_new_display"] =
                  "Share Transfer Completed";
                // console.log("5");
              } else if (
                res.data.confirmed_verified === "true" &&
                res.data.dealterm_uploaded === "true" &&
                res.data.user_signed_status === "complete" &&
                (res.data.order_status_code === "COMPLETED" ||
                  res.data.order_status_code === "CLOSED")
              ) {
                res.data["order_status_new"] = "Completed";
                res.data["order_status_new_display"] = "Completed";
                // console.log("6");
              } else if (res.data.order_status_code === "INACTIVE") {
                res.data["order_status_new"] = "INACTIVE";
                res.data["order_status_new_display"] = "Inactive";
                // console.log("7");
              } else {
                // console.log("8");
                if (res.data.user_signed_status === "complete") {
                  // console.log(1);
                  res.data["order_status_new"] = "Deal term";
                  res.data["order_status_new_display"] =
                    "Share Transfer Pending";
                } else if (
                  (res.data.user_signed_status === "pending" ||
                    res.data.user_signed_status == null) &&
                  res.data.order_status_code !== "CLOSED"
                ) {
                  // console.log(2);
                  res.data["order_status_new"] = "Deal term";
                  res.data["order_status_new_display"] = "Deal Term Pending";
                } else {
                  // console.log(3);
                  res.data["order_status_new"] = "Completed";
                  res.data["order_status_new_display"] = "Completed";
                }
              }
              let stepNameIndex =
                stepName.indexOf(res.data.order_status_new) + 1;
              setActiveStep(stepNameIndex);
            } else if (
              res.data.description === "Partner's Client" ||
              res.data.description === "Partner's RM" ||
              res.data.description === "Partner Admin"
            ) {
              let stepNameIndex =
                stepNamePartner.indexOf(res.data.order_status_code) + 1;
              setActiveStep(stepNameIndex);
            } else if (res.data.is_nse_trade) {
              let stepNameIndex =
                NSEStatus.indexOf(res.data.order_status_code) + 1;
              setActiveStep(stepNameIndex);
            } else {
              let stepNameIndex =
                stepNameOffline.indexOf(res.data.order_status_code) + 1;
              setActiveStep(stepNameIndex);
            }
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
        setOpen(false);
      })
      .catch((error) => {
        console.log(error);
        setOpen(false);
      });
  };

  const getVerifiedDetailsData = () => {
    serviceOrderService
      .getVerifiedData(useParamsId)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setVerifiedDetailsData(res.data);
            setIsVerifiedDetails(res.data.is_verified_all);
            // setMappedStatus(res.data.is_sell_buy_order_mapped);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getServiceOrderDocuments = () => {
    serviceOrderService
      .getServiceOrderDocuments(useParamsId)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setDocumentData(res.data);
            const isSignedFileExist =res.data.some(item =>item.document_name === 'Dealterm_signed')
            setIsSignedFileExist(isSignedFileExist)
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const getDealTermDocument = () => {
    serviceOrderService
      .getServiceOrderdDealTermDocuments(useParamsId)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            const file = new Blob([res.data], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            setDealTermFileData(fileURL);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
      });
  };

  useEffect(() => {
    getVerifiedDetailsData();
    getSecurityData(useParamsId);
    getServiceOrderDocuments();
    getDealTermDocument();
    getOrderBasicInfo();
    // InvoiceDocument()
  }, [useParamsId]);

  totalConsolidatedPrice = securityData.reduce((sum, item) => {
    return sum + Number(item.price) * Number(item.quantity);
  }, 0);

  let totalSoldPrice = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(totalConsolidatedPrice);

  let finalOrderPrice = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(
    parseFloat(
      securityData[0]?.is_tcs_tds_collected !== 0
        ? securityData[0]?.is_tcs_tds_collected === 1
          ? parseFloat(totalConsolidatedPrice) +
            parseFloat(securityData[0]?.is_tcs_tds_collected_value)
          : parseFloat(totalConsolidatedPrice) -
            parseFloat(securityData[0]?.is_tcs_tds_collected_value)
        : 0
    )
  );

  let tdsValue = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(
    securityData[0]?.is_tcs_tds_collected !== 0
      ? securityData[0]?.is_tcs_tds_collected_value
      : 0
  );

  const handleModalClose = () => {
    setOpenModal(false);
    setImageDialog(false);
  };

  const handleModalCloseComplete = () => {
    setCompleteOrderModel(false);
  };

  const updateOrderStatus = async () => {
    setIsDisabled(true);
    submitUpdatedStatus({
      service_order_id: serviceOrderData.id,
      status: "COMPLETED",
    });
  };

  const handleCommissionUpdate=(async(values)=>{
    const initValue ={
      commission_paid_date:commissionStatusDialog.item.commission_paid_date,
      commission_paid_status: commissionStatusDialog.item.commission_paid_status,
      id:commissionStatusDialog.item.id
    }
    const result =compareJSON(values,initValue)
    const data=[{
      id: values.id,
      order_id:serviceOrderData.id,
      commission_paid_date: values.commission_paid_date,
      commission_paid_status: values.commission_paid_status,
      updated_values:JSON.stringify(result)
    }]
    const formData = new FormData();
    formData.append('commission_data', JSON.stringify(data))
    formData.append('type', "single")
    const res =await serviceOrderService.commissionUpdate(formData)
    handleEditModalClose()
    if (res.status === 200) {
      if(serviceOrderData.commission_split_data.length ===1){
        const data = {
          commission_paid_status: values.commission_paid_status,
          order_id: serviceOrderData.id,
          commission_paid_date: values.commission_paid_date,
        }
        submitCommissionStatus(data)
      }else{
        setSnackBarOpen(true);
        getOrderBasicInfo();
        setSnackbarTitle(res.data);
        setSeverity("success");
      }
      setSpinner(false);
    } else if (res.status === 401) {
      setSpinner(false);
      setSnackBarOpen(true);
      setTitleModal()
      setSnackbarTitle(res.data.message);
      setSeverity("error");
    } else {
      setSpinner(false);
      setTitleModal()
      setSnackBarOpen(true);
      setSnackbarTitle("Something went wrong");
      setSeverity("error");
    }
  })

  const submitMappingServiceOrder = (values) => {
    const data = {};

    data.security_id = values.security_id;
    data.service_order_id = Number(values.service_order_id);
    data.order_supplies = values.order_supplies;

    serviceOrderService
      .postMappingServiceOrder(data)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setSnackbarTitle("Order Mapped");
            setSeverity("success");
            setOpenEditModal(false);
            getSecurityData(useParamsId);
            getVerifiedDetailsData();
            setSnackBarOpen(true);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            } else {
              setSnackbarTitle("Something went wrong!");
              setSeverity("error");
              setOpenEditModal(false);
              setSnackBarOpen(true);
            }
          }
        } else {
          setSnackbarTitle("Something went wrong!");
          setSeverity("error");
          setOpenEditModal(false);
          setSnackBarOpen(true);
        }
      })
      .catch((error) => {
        setSnackbarTitle("Something went wrong!");
        setSeverity("error");
        setOpenEditModal(false);
        setSnackBarOpen(true);
      });
  };

  const submitUpdatedAssign = (values) => {
    serviceOrderService
      .putAssigToOrder(values)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setOpenEditModal(false);
            getOrderBasicInfo();
            setSnackbarTitle("Assign Updated");
            setSnackBarOpen(true);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            } else {
              setSeverity("error");
              setSnackbarTitle("Invalid Token");
              setOpenEditModal(false);
              setSnackBarOpen(true);
            }
          }
        } else {
          setSeverity("error");
          setSnackbarTitle("Invalid Token");
          setOpenEditModal(false);
          setSnackBarOpen(true);
        }
      })
      .catch((error) => {
        setSeverity("error");
        setSnackbarTitle("Invalid Token");
        setOpenEditModal(false);
        setSnackBarOpen(true);
      });
  };

  const submitUpdatedStatus = async (values) => {
    if (values.status === "CLOSED" && isVerifiedDetails === false ) {
      setCompleteOrderModel(false)
      setSeverity("error");
      setSnackbarTitle(
        "Please verify and completed the checklist before closing the order"
      );
      setOpenEditModal(false);
      setSnackBarOpen(true);
    } else if(values.status ==='SHARETRANSFERPENDING'){
        serviceOrderService.putStatusOfOnlineServiceOrder(values).then((res)=>{
          if (res) {
            if (res.status === 200) {
              setOpenEditModal(false);
              getOrderBasicInfo();
              setSnackbarTitle("Status Updated");
              setSnackBarOpen(true);
            } else {
              if (
                res.status === 401 &&
                res.data.name === "TokenExpiredError"
              ) {
                setOpenSessionExpiredModal(true);
              } else {
                setSeverity("error");
                setSnackbarTitle("Invalid Token");
                setOpenEditModal(false);
                setSnackBarOpen(true);
              }
            }
          } else {
            setSeverity("error");
            setSnackbarTitle("Invalid Token");
            setOpenEditModal(false);
            setSnackBarOpen(true);
          }
        })
    }else{
          serviceOrderService
            .putStatusOfServiceOrder(values)
            .then((res) => {
              setCompleteOrderModel(false)
              if (res) {
                if (res.status === 200) {
                  setOpenEditModal(false);
                  getOrderBasicInfo();
                  setSnackbarTitle("Status Updated");
                  setSnackBarOpen(true);
                } else {
                  if (
                    res.status === 401 &&
                    res.data.name === "TokenExpiredError"
                  ) {
                    setOpenSessionExpiredModal(true);
                  } else {
                    setSeverity("error");
                    setSnackbarTitle("Invalid Token");
                    setOpenEditModal(false);
                    setSnackBarOpen(true);
                  }
                }
              } else {
                setCompleteOrderModel(false)
                setSeverity("error");
                setSnackbarTitle("Invalid Token");
                setOpenEditModal(false);
                setSnackBarOpen(true);
              }
            })
            .catch((error) => {
              setCompleteOrderModel(false)
              setSeverity("error");
              setSnackbarTitle("Invalid Token");
              setOpenEditModal(false);
              setSnackBarOpen(true);
            });
        }
  };

  const submitHandleDocumentModalData = (values, docName) => {
    var count = documentData.filter(function(element) {
      return (
        element.deal_term_document_type_description === `${values.document_name}`
      );
    }).length;
    let docCount = count !== 0 ? `(${count})` : "";
    const name =
      values.document_type === "SIGNED_DEAL_TERM"
        ? `${docName}`
        : docCount
        ? `${serviceOrderData.service_id} ${docName} ${docCount}`
        : `${serviceOrderData.service_id} ${values.document_name}`;
    const formData = new FormData();
    formData.append("document_type", values.document_type);
    formData.append("document_name", name);
    formData.append("document_reference_date", values.date);
    formData.append("amount", values.amount);
    formData.append("file", values.file);
    formData.append(
      "document_reference_id",
      serviceOrderData.is_nse_trade &&
        (values.document_type === "STAGE_ONE_DOCUMENT" ||
          values.document_type === "STAGE_TWO_DOCUMENT")
        ? values.amount
        : ""
    );
    formData.append(
      "related_service_order_id",
      values.related_service_order_id
    );
    if (
      values.document_type === "PAYMENT_RECEIVED_PROOF" ||
      values.document_type === "SHARE_TRANSFERED_PROOF"
    ) {
      formData.append(
        "security_transaction_id",
        values.document_type === "SHARE_TRANSFERED_PROOF"
          ? values.referenceId
          : verifiedDetailsData.security_transaction_id.value
      );
      formData.append(
        "payment_transaction_id",
        values.document_type === "PAYMENT_RECEIVED_PROOF"
          ? values.referenceId
          : verifiedDetailsData.payment_transaction_id.value
      );
      formData.append(
        "is_payment_recieved_or_transferred_verified",
        values.document_type === "PAYMENT_RECEIVED_PROOF"
          ? values.isVerified
          : verifiedDetailsData.is_payment_recieved_or_transferred_verified
              .value
      );
      formData.append(
        "is_security_recieved_or_transferred_verified",
        values.document_type === "SHARE_TRANSFERED_PROOF"
          ? values.isVerified
          : verifiedDetailsData.is_security_recieved_or_transferred_verified
              .value
      );
      formData.append(
        "is_client_details_verified",
        verifiedDetailsData.is_client_details_verified.value
      );
      formData.append(
        "is_demat_account_verified",
        verifiedDetailsData.is_demat_account_verified.value
      );
      formData.append(
        "is_sell_buy_order_mapped",
        verifiedDetailsData.is_sell_buy_order_mapped
      );
      formData.append(
        "document_reference_id",
        serviceOrderData.is_nse_trade &&
          (values.document_type === "STAGE_ONE_DOCUMENT" ||
            values.document_type === "STAGE_TWO_DOCUMENT")
          ? values.amount
          : values.referenceId
      );
      formData.append(
        "is_shared_credit_confirmed_verified",
        verifiedDetailsData.is_shared_credit_confirmed_verified.value
      );
      formData.append(
        "is_signed_deal_term_uploaded_verified",
        verifiedDetailsData.is_signed_deal_term_uploaded_verified.value
      );
    }

    serviceOrderService
      .postServiceOrderDocuments(formData)
      .then((res) => {
        if (res) {
          if (res.status === 201) {
            setOpenModal(false);
            getServiceOrderDocuments();
            getVerifiedDetailsData();
            setSnackbarTitle("Document Uploaded ");
            setSnackBarOpen(true);
            setSeverity("success");
            getOrderBasicInfo();
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            } else {
              setSeverity("error");
              setSnackbarTitle("Invalid Token");
              setOpenModal(false);
              setSnackBarOpen(true);
            }
          }
        } else {
          setSeverity("error");
          setSnackbarTitle("Invalid Token");
          setOpenModal(false);
          setSnackBarOpen(true);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setSeverity("error");
        setSnackbarTitle("Invalid Token");
        setOpenModal(false);
        setSnackBarOpen(true);
      });
  };

  const handleRequestEdit=(async(values)=>{
    const data={
      orderid:serviceOrderData.service_id,
      fields: values.orderList,
  }
    const res =await serviceOrderService.requestOrderUpdate(data)
    setSalesEditDialog(false)
    if (res.status === 200) {
      setSpinner(false);
      setSnackBarOpen(true);
      getOrderBasicInfo();
      setSnackbarTitle("Request sent");
      setSeverity("success");
    } else if (res.status === 401) {
      setSpinner(false);
      setSnackBarOpen(true);
      setTitleModal()
      setSnackbarTitle("Request failed");
      setSeverity("error");
    } else {
      setSpinner(false);
      setTitleModal()
      setSnackBarOpen(true);
      setSnackbarTitle("Something went wrong");
      setSeverity("error");
    }
  })

  const handleFileDialog = (item) => {
    setShow(true);
    setUrl({
      url: null,
      title: item.document_name,
      isPdf: null,
    });
    getLookUpDataService.getObjectUrl(item.fileid).then((res) => {
      if (res.data.success) {
        setUrl({
          url: res.data.data,
          title: item.document_name,
          isPdf:
            res.data.mimetype === "application/pdf" ||
            res.data.mimetype === ".pdf"
              ? true
              : false,
        });
      } else {
        setShow(false);
      }
    });
  };

 

  const submitMailTemplate = (values) => {
    const regex = /<br>&nbsp;/g;

    const formData = new FormData();
    formData.append("order_id", useParamsId);
    formData.append("subject", values.subject);
    formData.append(
      "body",
      values.email_template
        .replace(regex, "")
        .concat(
          "<strong>Disclaimer:</strong><i>NSE share transfers are subject to approval from NSE itself upon analyzing the fit and proper for each client. UnlistedKart does not have control over the timelines for NSE trades.<i/>"
        )
    );
    formData.append("template_id", values.mail_type.id);
    formData.append("file", values.file);
    serviceOrderService.sendNseEmail(formData).then((res) => {
      if (res.status === 200) {
        setSeverity("success");
        setSnackbarTitle("Mail sent Successfully");
        setSnackBarOpen(true);
        setOpenModal(false);
      } else {
        setSeverity("error");
        setSnackbarTitle("Failed to send email");
        setSnackBarOpen(true);
        setOpenModal(false);
      }
      getNSEMailHistory();
    });
  };

  const handleDownload = async (item) => {
    try {
      await getLookUpDataService.getObjectUrl(item.fileid).then(async (res) => {
        if (res.data.success) {
          const fileUrl = res.data.data;

          const options = {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          };

          const response = await fetch(fileUrl, options);

          if (response.status === 200) {
            const file = await response.blob();
            const filename = response.headers["Content-Disposition"].split(
              "filename="
            )[1];
            await saveFile(file, filename);
          }
        }
      });
    } catch (error) {
      setSeverity("error");
      setSnackbarTitle("Failed to download");
      setSnackBarOpen(true);
    }
  };

  const saveFile = async (file, filename) => {
    await new Promise((resolve, reject) => {
      let blobURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = blobURL;
      link.setAttribute("download", filename);
      link.click();
      blobURL = null;
      resolve();
    });
  };

  const handleEditModalClose = () => {
    setOpenEditModal(false);
    setSalesEditDialog(false)
    setCommissionStatusDialog({
      open:false,
      item:null
    })
  };

  const CopyTextTable = (value) => {
    return (
      <div
        onClick={() => onHover(value)}
        onMouseLeave={onLeave}
        className={classes.formgrgroup}
      >
        <Tooltip
          placement="right"
          className={classes.tooltip}
          title={copy ? "Copied" : "Click to Copy"}
        >
          <Typography>{value}</Typography>
        </Tooltip>
      </div>
    );
  };

  const submitHandleVerifiedData = (values) => {
    let assignData = {};
    let statusData = {};

    if (values.assign !== "") {
      assignData.assign = values.assign;
      assignData.service_order_id = values.service_order_id;

      serviceOrderService
        .putAssigToOrder(assignData)
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              setSeverity("success");
              setSnackbarTitle("Assign Updated Successfully");
              setSnackBarOpen(true);
            }
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            } else {
              setSeverity("error");
              setSnackbarTitle("Failed to Update assign");

              setSnackBarOpen(true);
            }
          }
        })
        .catch((error) => {
          setSeverity("error");
          setSnackbarTitle("Invalid Token");
          setOpenModal(false);
          setSnackBarOpen(true);
        });
    }

    delete values.assign;
    serviceOrderService
      .putVerifiedData(values)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            if (verifyModalMode === "create") {
              statusData.service_order_id = values.service_order_id;
              statusData.status = stepName[activeStep];
              serviceOrderService
                .putStatusOfServiceOrder(statusData)
                .then((res) => {
                  if (res) {
                    if (res.status === 200) {
                      setOpenModal(false);
                      getOrderBasicInfo();
                      getVerifiedDetailsData();
                      setSnackbarTitle(
                        `Status Changed to ${stepName[activeStep]}`
                      );
                      setTimeout(() => setSnackBarOpen(true), 1500);
                    } else {
                      setSeverity("error");
                      setSnackbarTitle("Invalid Token");
                      setOpenModal(false);
                      setSnackBarOpen(true);
                    }
                  } else {
                    if (
                      res.status === 401 &&
                      res.data.name === "TokenExpiredError"
                    ) {
                      setOpenSessionExpiredModal(true);
                    } else {
                      setSeverity("error");
                      setSnackbarTitle("Invalid Token");
                      setOpenModal(false);
                      setSnackBarOpen(true);
                    }
                  }
                });
            } else {
              setOpenModal(false);
              setSnackBarOpen(true);
              getOrderBasicInfo();
              getVerifiedDetailsData();
            }
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        } else {
          setSeverity("error");
          setSnackbarTitle("Invalid Token");
          setOpenModal(false);
          setSnackBarOpen(true);
        }
      })
      .catch((error) => {
        setSeverity("error");
        setSnackbarTitle("Invalid Token");
        setOpenModal(false);
        setSnackBarOpen(true);
      });
  };

  // const downloadImage = (imageSrc, name) => {
  //   fetch(imageSrc)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       const a = document.createElement("a");
  //       const url = window.URL.createObjectURL(blob);
  //       a.href = url;
  //       a.download = name;
  //       a.click();
  //       window.URL.revokeObjectURL(url);
  //     });
  // };

  const getMuiTheme = GetMuiTheme
  const SecurityColumn = [
    {
      name: "security_id",
      label: "securityID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "service_order_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "is_security_mapped",
      label: "Map",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },

    {
      name: "slno",
      label: "Sl No",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography
              variant="body2"
              // component={LinkBtn}
              noWrap={false}
              // to={`/app/service-order-details/${tableMeta.rowData[1]}`}
              color="primary"
            >
              {tableMeta.rowIndex + 1}
            </Typography>
          );
        },
      },
    },
    {
      name: "security_name",
      label: "Scrip Name",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return CopyTextTable(value);
        },
      },
    },
    {
      name: "isin",
      label: "ISIN",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return CopyTextTable(value);
        },
      },
    },
    {
      name: "quantity",
      label: "Quantity",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return CopyTextTable(value);
        },
      },
    },
    {
      name: "procurement_price",
      label: "Buy Price",
      options: {
        filter: false,
        sort: false,
        display: serviceOrderType === "SELL" ? true : false,
        customBodyRender: (value) => {
          var floatValue = Number(value).toFixed(3);
          return CopyTextTable(floatValue);
        },
      },
    },
    {
      name: "price",
      label:
        serviceOrderData?.order_type_code === "SELL"
          ? "Sold Price"
          : "Buy/Client Price",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          var floatValue = Number(value).toFixed(3);
          return CopyTextTable(floatValue);
        },
      },
    },

    {
      name: "order_supplies",
      label: "Order Supplies",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },

    {
      name: "commision",
      label: "RM Price",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var floatValue = Number(value).toFixed(3);
          return CopyTextTable(floatValue);
        },
      },
    },
    {
      name: "facilitator_price",
      label: "Facilitator Price",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var floatValue = Number(value).toFixed(3);
          return CopyTextTable(floatValue);
        },
      },
    },
    {
      name: "procured_by",
      label: "Procured By",
      options: {
        filter: false,
        sort: false,
        display: serviceOrderType === "SELL" ? true : false,
        customBodyRender: (value) => {
          return (
            <>
              <Tooltip1 title={renderTooltipTitle(value)}>
                <InfoIcon />
              </Tooltip1>
            </>
          );
        },
      },
    },
    {
      name: "consolidated_price",
      label: "Total Amount",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var floatValue = Number(value).toFixed(3);
          var buyquantity = Number(
            tableMeta.tableData[tableMeta.rowIndex].quantity
          ).toFixed(3);

          return CopyTextTable((floatValue * buyquantity).toFixed(3));
        },
      },
    },
    {
      name: "map",
      label: "Related Buy Order",
      options: {
        filter: false,
        sort: false,
        display:
          serviceOrderType === "SELL" &&
          (role === "OPERATION" || role === "ADMIN")
            ? true
            : false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {tableMeta.rowData[2] === "NOT_MAPPED" ? (
                <Button
                  variant="outlined"
                  // noWrap={false}
                  onClick={() => {
                    setRequiredQuantity(tableMeta.rowData[6]);
                    handleMappingServiceOrderModal(tableMeta.rowData[0]);
                  }}
                  color="primary"
                >
                  Update Inventory
                </Button>
              ) : (
                <>
                  {tableMeta.rowData[2] === "MAPPED"
                    ? tableMeta.rowData[9].map((item, i) => {
                        return (
                          <Grid item xs={3} sm={3} md={6} lg={6} key={i}>
                            <Typography variant="body2">
                              <Link
                                component={LinkBtn}
                                to={`/app/service-order-details/${item.buy_order_id}`}
                                color="primary"
                                onClick={() => {
                                  setUserParamsId(item.buy_order_id);
                                }}
                                style={{ marginRight: 5 }}
                              >
                                {item.service_id}
                              </Link>
                            </Typography>
                          </Grid>
                        );
                      })
                    : ""}
                </>
              )}
            </>
          );
        },
      },
    },
  ];

  const getSignedUrl = (url) => {
    setImageDialog(true);
    getLookUpDataService.getKycUrl(url).then((res) => {
      setImageUrl(res.data.data);
    });
  };

  const renderTooltipTitle = (item) => {
    return (
      <Root>
      <Box style={{ margin: 0, padding: "10px" }}>
        <Box className={classes.table}>
          <table>
            <tr>
              <td style={{ fontWeight: 500 }}>Name</td>
              <td style={{ fontWeight: 500, margin: "1rem" }}>Qty</td>
              <td style={{ fontWeight: 500 }}>Price</td>
            </tr>
            {item?.map((item) => {
              return (
                <tr key={item.name}>
                  <td>{item.name}</td>
                  <td style={{ marginLeft: "1rem" }}>{item.value}</td>
                  <td>{item.procured_price}</td>
                </tr>
              );
            })}
          </table>
        </Box>
      </Box>
      </Root>
    );
  };

  function checkFileType(url) {
    var extension = url
      ?.split(".")
      .pop()
      .toLowerCase();
    if (["jpg", "jpeg", "png", "gif"].indexOf(extension) >= 0) {
      return "image";
    } else if (["pdf"].indexOf(extension) >= 0) {
      return "pdf";
    } else {
      return "unknown";
    }
  }

  const RMSellCommission = securityData?.map((item) => {
    return (
      (Number(item.price)?.toFixed(3) - Number(item.commision)?.toFixed(3)) *
      Number(item.quantity)
    );
  });

  const RMBuyCommission = securityData?.map((item) => {
    return (
      (Number(item.commision)?.toFixed(3) - Number(item.price)?.toFixed(3)) *
      Number(item.quantity)
    );
  });

  const FacilitatorSellCommission = securityData?.map((item) => {
    return (
      (Number(item.price)?.toFixed(3) - Number(item.facilitator_price)?.toFixed(3)) *
      Number(item.quantity)
    );
  });

  const FacilitatorBuyCommission = securityData?.map((item) => {
    return (
      (Number(item.facilitator_price)?.toFixed(3) - Number(item.price)?.toFixed(3)) *
      Number(item.quantity)
    );
  });

  const totalCommissionPrice1 =
    serviceOrderData.order_type_code === "BUY"
      ? RMBuyCommission.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        )
      : RMSellCommission.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );

        const totalFacilitatorCommissionPrice1 =
        serviceOrderData.order_type_code === "BUY"
          ? FacilitatorBuyCommission.reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0
            )
          : FacilitatorSellCommission.reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0
            );   
            
            
    let totalFacilitatorCommissionPrice = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format((totalFacilitatorCommissionPrice1));

  let totalCommissionPrice = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format((totalCommissionPrice1));
  const imageType =
    serviceOrderData && checkFileType(serviceOrderData.client_payment_proof);

  const buyTotalPrice = () => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(sum(totalConsolidatedPrice, totalCommissionPrice1));
  };

  const sum = (a, b) => {
    return parseInt(a) + b;
  };

  const handleCommissionDialog=((value)=>{
    setCommissionStatusDialog({
      open:true,
      item:value
    })
  })

  const SecurityColumnOnline = [
    {
      name: "security_id",
      label: "securityID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "service_order_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "is_security_mapped",
      label: "Map",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },

    {
      name: "slno",
      label: "Sl No",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography
              variant="body2"
              // component={LinkBtn}
              noWrap={false}
              // to={`/app/service-order-details/${tableMeta.rowData[1]}`}
              color="primary"
            >
              {tableMeta.rowIndex + 1}
            </Typography>
          );
        },
      },
    },
    {
      name: "security_name",
      label: "Scrip Name",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return CopyTextTable(value);
        },
      },
    },
    {
      name: "isin",
      label: "ISIN",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return CopyTextTable(value);
        },
      },
    },
    {
      name: "quantity",
      label: "Quantity",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return CopyTextTable(value);
        },
      },
    },
    {
      name: "price",
      label: "Price",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var floatValue = parseFloat(value).toFixed(3);
          // console.log(floatValue)
          var finalValue = parseFloat(
            floatValue / tableMeta.tableData[tableMeta.rowIndex].quantity
          ).toFixed(3);
          return CopyTextTable(finalValue);
        },
      },
    },

    {
      name: "order_supplies",
      label: "Order Supplies",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "consolidated_price",
      label: "Total Price (QxP)",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var floatValue = Number(value).toFixed(3);
          // var buyquantity = Number(
          //   tableMeta.tableData[tableMeta.rowIndex].quantity
          // ).toFixed(3);
          return CopyTextTable(floatValue);
        },
      },
    },
    // {
    //   name: "map",
    //   label: "Related Buy Order",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     display:
    //       serviceOrderType === "SELL" &&
    //         (role === "OPERATION" || role === "ADMIN")
    //         ? true
    //         : false,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <>
    //           {tableMeta.rowData[2] === "NOT_MAPPED" ? (
    //             <Button
    //               variant="outlined"
    //               noWrap={false}
    //               onClick={() => {
    //                 setRequiredQuantity(tableMeta.rowData[5]);
    //                 handleMappingServiceOrderModal(tableMeta.rowData[0]);
    //               }}
    //               color="primary"
    //             >
    //               Update Inventory
    //             </Button>
    //           ) : (
    //             <>
    //               {tableMeta.rowData[2] === "MAPPED"
    //                 ? tableMeta.rowData[7].map((item) => {
    //                   return (
    //                     <Grid item xs={3} sm={3} md={6} lg={6}>
    //                       <Typography variant="body2">
    //                         <Link
    //                           component={LinkBtn}
    //                           to={`/app/service-order-details/${item.buy_order_id}`}
    //                           color="primary"
    //                           onClick={() => {
    //                             setUserParamsId(item.buy_order_id);
    //                           }}
    //                           style={{ marginRight: 5 }}
    //                         >
    //                           {item.service_id}
    //                         </Link>
    //                       </Typography>
    //                     </Grid>
    //                   );
    //                 })
    //                 : ""}
    //             </>
    //           )}
    //         </>
    //       );
    //     },
    //   },
    // },
  ];

  const deleteDocument = () => {
    setSpinner(true);

    serviceOrderService
      .deleteServiceOrderDocuments(documentID)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            getServiceOrderDocuments();
            setDeleteConfirm(false);
            setSpinner(false);
            setSeverity("success");
            getVerifiedDetailsData();
            setSnackbarTitle("The file has been  Deleted Successfully");
            setSnackBarOpen(true);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            } else {
              setDeleteConfirm(false);
              setSpinner(false);
              setSeverity("error");
              setSnackbarTitle("Failed to Delete File");
              setSnackBarOpen(true);
            }
          }
        }
      })
      .catch((error) => {
        setDeleteConfirm(false);
        setSpinner(false);
        setSeverity("error");
        setSnackbarTitle("Failed to Delete File");
        setSnackBarOpen(true);
      });
  };

  const handlefileDelete = (docID) => {
    setDeleteConfirm(true);
    setDocumentID(docID);
  };

  // const handleOpenDealTerm = () =>{
  //     window.open(dealTermFileData, "_blank");
  // }

  const handleEditBtn = () => {
    navigate("/app/service-order-create?mode=edit", {
      replace: true,
      state: { userID: useParamsId,
        page: location?.state?.page,
                  orderType: location?.state?.orderType,
                  advanceSearchForm: location?.state?.advanceSearchForm,
                  tabValue: serviceOrderData.order_type_code === "BUY" ? 1 : 0,
                  mainTabValue: serviceOrderData.is_purchased_online ? 0 : 1,
                  data:location.state?.data,
                  index:location.state?.index
       },
    });
  };

  // const handleChange = (event) => {
  //   console.log();
  //   if (event.target.checked) {
  //     setisSignatureApproved(true)
  //     // setValidateSignature(true)
  //   } esetisSignatureApprovedlse {
  //     setisSignatureApproved(false)
  //     setValidateSignature(true)
  //   }

  //   setChecked(event.target.checked);
  // };

  const updateRemarks = async (values) => {
    setOpen(true);
    await serviceOrderService
      .updateRemarks({
        remarksForOrder: values.remarks,
        orderid: serviceOrderData.id,
      })
      .then((res) => {
        getOrderBasicInfo();
        setOpen(false);
      });
  };

  const onHover = (value) => {
    setCopy(true);
    navigator.clipboard.writeText(value);
  };

  const onLeave = () => {
    setCopy(false);
  };

  function getOnlineSteps() {
    return [
      { label: "Inactive", message: "" },
      { label: "Deal term", message: "Make it dealterm pending" },
      { label: "Share transfer", message: "Make it Active" },
      { label: "Closed", message: "Make it Closed" },
    ];
  }

  function getNSESteps() {
    return [
      { label: "Inactive", message: "" },
      { label: "Active", message: "Make it Active" },
      { label: "Processing", message: "Make it Processing" },
      { label: "Stage 1 Processing", message: "Make it Stage 1 Processing" },
      { label: "Stage 1 Processed", message: "Make it Stage 1 Processed" },
      { label: "Stage 2 Processing", message: "Make it Stage 2 Processing" },
      { label: "Stage 2 Processed", message: "Make it Stage 2 Processed" },
      { label: "Completed", message: "Make it Completed" },
      { label: "Closed", message: "Make it Closed" },
    ];
  }

  function getOfflineSteps() {
    return [
      { label: "Inactive", message: "" },
      { label: "Active", message: "Make it Active" },
      { label: "Processing", message: "Make it Processing" },
      { label: "Completed", message: "Make it Completed" },
      { label: "Closed", message: "Make it Closed" },
    ];
  }

  function getPartnerSteps() {
    return [
      { label: "Inactive", message: "" },
      { label: "Cancelled", message: "Make it Cancelled" },
      { label: "OnHold", message: "Make it OnHold" },
      { label: "Pre-processing", message: "Make it Pre-processing" },
      { label: "Active", message: "Make it Active" },
      { label: "Processing", message: "Make it Processing" },
      { label: "Completed", message: "Make it Completed" },
      { label: "Closed", message: "Make it Closed" },
    ];
  }

  // const [module, setModule] = React.useState(true);

  // console.log("stepNameIndex",stepNameIndex);

  const steps =
    serviceOrderData && serviceOrderData.is_purchased_online
      ? getOnlineSteps()
      : serviceOrderData.description === "Partner's Client" ||
        serviceOrderData.description === "Partner's RM" ||
        serviceOrderData.description === "Partner Admin"
      ? getPartnerSteps()
      : serviceOrderData.is_nse_trade
      ? getNSESteps()
      : getOfflineSteps();
  // setActiveStep(stepNameIndex)

  const handleStep = (step) => () => {
    if (step >= activeStep && step < activeStep + 2) {
      if (activeStep < step) {
        handleVerificationModal();
      }
    }
  };

  const CopyText = (value) => {
    return (
      <Tooltip placement="right"
        className={classes.tooltip}
        title={copy ? "Copied" : "Click to Copy"}
      >
        <p
          className={classes.details}
          onClick={() => onHover(value)}
          onMouseLeave={onLeave}
        >
          {value}
        </p>
      </Tooltip>
    );
  };
  const CustomTooltip =(({label,value})=>{
    return(
      <div className={classes.formgrgroup}>
      <label className={classes.labelname}>{label}</label>

      <Tooltip placement="right"
        className={classes.tooltip}
        title={copy ? "Copied" : "Click to Copy"}
      >
        <p
          onClick={() => {
            onHover(value);
          }}
          onMouseLeave={onLeave}
          className={classes.details}
        >
          {value}
        </p>
      </Tooltip>
    </div>
    )
  })

  return (
    <Root>
    <Page title="Unlistedkart | Order Details">
      <div className={classes.hdrlblbtn}>
        <Button
          className={classes.editbutton}
          variant="contained"
          color="primary"
          size="medium"
          onClick={() => {
            if (location?.state?.partner) {
              navigate("/app/partner_orders");
            } else {
              navigate("/app/service-order-lists", {
                replace: true,
                state: {
                  page: location?.state?.page,
                  orderType: location?.state?.orderType,
                  advanceSearchForm: location?.state?.advanceSearchForm,
                  tabValue: serviceOrderData.order_type_code === "BUY" ? 1 : 0,
                  mainTabValue: serviceOrderData.is_purchased_online ? 0 : 1,
                },
              });
            }
          }}
        >
          Back
        </Button>
        <Typography className={classes.heder} variant="h4">
          Order Details
        </Typography>

        {serviceOrderData.is_purchased_online ? (
          <>
            <Grid>
              <p></p>
            </Grid>
          </>
        ) : (
          <>
            {role === "ADMIN" || role === "OPERATION" ? (
              <Button
                className={classes.editbutton}
                variant="contained"
                color="primary"
                size="medium"
                onClick={handleEditBtn}
              >
                Edit Order
              </Button>
            ) :role === "SALES" ? (
              <Button
                className={classes.editbutton}
                variant="contained"
                color="primary"
                size="medium"
                onClick={()=>setSalesEditDialog(true)}
              >
                Request edit order
              </Button>
            ): <div></div>}
          </>
        )}
        {/* */}
      </div>
      <NavigationArrowIconButton
        setUserParamsId={setUserParamsId}
        url={"/app/service-order-details"}
      />

      <ThemeProvider theme={getMuiTheme}>
        <div className={classes.root}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((data, index) => {
              if (index === activeStep) {
                return (
                  <Tooltip title={data.message} arrow key={index}>
                    <Step key={data.label} data-tip data-for="registerTip">
                      <StepLabel
                        className={classes.stepname}
                        sx={{fontSize:'0.67rem'}}
                        onClick={role !=='ADMIN_VIEWER' && handleStep(index + 1)}
                        // activeStep={activeStep[index]}
                      >
                        {data.label}
                      </StepLabel>
                    </Step>
                  </Tooltip>
                );
              } else {
                return (
                  <Step key={data.label} data-tip data-for="registerTip">
                    <StepLabel
                      className={classes.stepname}
                      onClick={handleStep(index + 1)}
                      sx={{fontSize:'0.67rem !important'}}
                      // activeStep={activeStep[index]}
                    >
                      {data.label}
                    </StepLabel>
                  </Step>
                );
              }
            })}
          </Stepper>
        </div>
      </ThemeProvider>

      <NormalCard title="Basic info">
        <div className={classes.induvidual}>
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Type</label>
            <p className={classes.details}>
              {serviceOrderData.order_type_code === "SELL" ? "Sell" : "Buy"}
            </p>
          </div>
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Transaction Type</label>
            <p className={classes.details}>
              {serviceOrderData.transaction_type_code === "INTERMEDIATE"
                ? "Intermediate"
                : "Direct"}
            </p>
          </div>
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>
              Beneficiary added status
            </label>
            <p className={classes.details}>
              {serviceOrderData.is_investor_beneficiary_added ? (
                <Tooltip title="Added" placement="right">
                  <span>
                    <CheckCircleOutlineIcon
                      style={{ color: "green", cursor: "pointer" }}
                    />
                  </span>
                </Tooltip>
              ) : (
                <Tooltip title="Not added" placement="right">
                  <span>
                    <CancelIcon color={"error"} style={{ cursor: "pointer" }} />
                  </span>
                </Tooltip>
              )}
            </p>
          </div>
          {serviceOrderData.transaction_type_code === "INTERMEDIATE" &&
          serviceOrderData.channel_partner !== null ? (
            <>
            <CustomTooltip label={"Channel Partner"} value={serviceOrderData.channel_partner} />
            <CustomTooltip label={"Channel Partner PAN No"} value={serviceOrderData.channel_partner_pan?.toUpperCase()} />
             
            </>
          ) : (
            <></>
          )}

          {serviceOrderData.is_nse_trade ? (
            <>
              <div className={classes.formgrgroup}>
                <label className={classes.labelname}>NSE Order</label>
                <p className={classes.details}>
                  <b>
                    {" "}
                    {serviceOrderData.is_nse_trade === true ? "Yes" : "No"}
                  </b>
                </p>
              </div>
              <div className={classes.formgrgroup}>
                <label className={classes.labelname}>NSE SPA Type</label>
                <p className={classes.details}>
                  {serviceOrderData.spa_type === 1
                    ? "Single SPA"
                    : "Double SPA"}
                </p>
              </div>
              <CustomTooltip label={"NSE Seller"} value={serviceOrderData.nse_seller_one} />

             
            </>
          ) : null}
              <CustomTooltip label={serviceOrderData.order_type_code === "SELL"
                ? "Investor"
                : "Vendor"} value={serviceOrderData.order_type_code === "SELL"
                ? serviceOrderData.investor
                : serviceOrderData.vendor} />
           <CustomTooltip label={serviceOrderData.order_type_code === "SELL"
                ? "Investor PAN No"
                : "Vendor PAN No"} value={serviceOrderData.order_type_code === "SELL"
                ? serviceOrderData?.investor_pan?.toUpperCase()
                : serviceOrderData?.vendor_pan?.toUpperCase()} />
        
        
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Status</label>
            <p className={classes.details}>
              {serviceOrderData.order_status_new_display === "Completed"
                ? "Closed"
                : serviceOrderData.order_status_new_display}{" "}
              {/* {serviceOrderData.is_purchased_online === true ? <></> : (<span>*/}
              {!serviceOrderData.order_status_new_display &&
                serviceOrderData.order_status}{" "}
              {role === "ADMIN" || role === "OPERATION" ? (
                <Link
                  style={{ fontSize: "0.8rem" }}
                  color="primary"
                  component="button"
                  onClick={() => {
                    handleStatusModal();
                  }}
                >
                  Change
                </Link>
              ) : null}
              {/* </span>)} */}
            </p>
          </div>
          <CustomTooltip label={"Lead Owner"} value={serviceOrderData.lead_owner} />
          {serviceOrderData.seller_type ===2 && 
          <>
          <CustomTooltip label={"Faciltator"} value={serviceOrderData.facilitator} />
          <CustomTooltip label={"Faciltator PAN"} value={serviceOrderData.facilitator_pan} />
          <CustomTooltip label={"Disclose Client Details"} value={serviceOrderData.disclose_client_details ? "Yes" : "No"} />
          <CustomTooltip label={"Facilitating Type"} value={serviceOrderData.facilitating_type === 3 ? "Sell and Buy" :serviceOrderData.facilitating_type === 2 ? "Buy" :"Sell"} />
          </>
          }
          {serviceOrderData.is_purchased_online ? (
            <></>
          ) : (
            <>
              {serviceOrderData.co_managed_one && (
          <CustomTooltip label={"Co Managed 1"} value={serviceOrderData.co_managed_one} />
              )}
              {serviceOrderData.co_managed_two && (
          <CustomTooltip label={"Co Managed 2"} value={serviceOrderData.co_managed_two} />
              )}
            </>
          )}

          {serviceOrderData.is_purchased_online ? (
            <></>
          ) : (
            <div className={classes.formgrgroup}>
              <label className={classes.labelname}>Assign</label>
              <p className={classes.details}>
                <Tooltip
                  onClick={() => {
                    onHover(serviceOrderData.assign);
                  }}
                  placement="right"
                  onMouseLeave={onLeave}
                  className={classes.tooltip}
                  title={copy ? "Copied" : "Click to Copy"}
                >
                  <span>{serviceOrderData.assign} </span>
                </Tooltip>
                <span>
                { role === "ADMIN_VIEWER" ? null : <Link
                    style={{ fontSize: "0.8rem" }}
                    color="primary"
                    size="small"
                    component="button"
                    onClick={() => {
                      handleAssignModal();
                    }}
                  >
                    Change
                  </Link>}
                </span>{" "}
              </p>
            </div>
          )}
          <CustomTooltip label={"Order ID"} value={serviceOrderData.is_purchased_online
                ? serviceOrderData.buy_order_id
                : serviceOrderData.service_id} />

        
          {serviceOrderData.is_purchased_online ? (
            <></>
          ) : (        
            <div className={classes.formgrgroup}>
              <label className={classes.labelname}>
                Order for Online Request
              </label>
              <p className={classes.details}>
                {serviceOrderData.is_purchased_online === true ? "Yes" : "No"}
              </p>
            </div>
          )}
        </div>
        <div className={classes.formgrgroup}>
          <label className={classes.labelname}>Role</label>
          <p className={classes.details}>{serviceOrderData.description}</p>
        </div>
        {(serviceOrderData.description === "Partner's Client" ||
          serviceOrderData.description === "Partner's RM") &&
          serviceOrderData.admin_name && (
            <div className={classes.formgrgroup}>
              <label className={classes.labelname}>
                Partner Representative
              </label>
              <p className={classes.details}>
                {serviceOrderData.admin_name}
                {serviceOrderData.admin_pan &&
                  ` (${serviceOrderData.admin_pan})`}
              </p>
            </div>
          )}

        {(serviceOrderData.description === "Partner's Client" ||
          serviceOrderData.description === "Partner's RM" ||
          serviceOrderData.description === "Partner Admin") && (
          <>
            <div className={classes.formgrgroup}>
              <label className={classes.labelname}>Payment Proof</label>
              <div className={classes.details}>
                {serviceOrderData.is_client_uploaded_payment_proof === true ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      const url = serviceOrderData.client_payment_proof.split(
                        "amazonaws.com/"
                      );
                      const fileName =
                        url.length > 1
                          ? url[1]
                          : serviceOrderData.client_payment_proof;
                      getSignedUrl(fileName);
                    }}
                  >
                    <ImageIcon />
                    View
                    {/* <img
                      onClick={() => {
                        setImageDialog(true);
                        setImageUrl(serviceOrderData.client_payment_proof);
                      }}
                      src={
                        imageType === "image"
                          ? serviceOrderData.client_payment_proof
                          : PDF
                      }
                      width={30}
                      height={30}
                      alt="img"
                    />
                    <GetAppIcon
                      onClick={() =>
                        downloadImage(
                          serviceOrderData.client_payment_proof,
                          imageType === "image"
                            ? `${serviceOrderData.service_id}.jpg`
                            : `${serviceOrderData.service_id}.pdf`
                        )
                      }
                      className={classes.download}
                      style={{ marginLeft: "4px" }}
                      color="primary"
                      fontSize="medium"
                    /> */}
                    {/* </a> */}
                  </div>
                ) : (
                  <>
                    {" "}
                    <span>Pending</span>
                    <CancelIcon
                      className={classes.unCheck}
                      style={{ marginLeft: "4px" }}
                      color="primary"
                      fontSize="medium"
                    />
                  </>
                )}
              </div>
            </div>
            <CustomTooltip label={"Payment through"} value={serviceOrderData.paid_through === "imps"
                  ? "Bank IMPS"
                  : serviceOrderData.paid_through === "unlistedkart"
                  ? "Unlistedkart Razorpay"
                  : serviceOrderData.paid_throgh} />
           
          </>
        )}
        {serviceOrderData.payment_utr_no && (
            <CustomTooltip label={"UTR Number"} value={serviceOrderData.payment_utr_no} />
        )}
      </NormalCard>
      <NormalCard title="Security Details">
        <div>
          {serviceOrderData.is_purchased_online ? (
            <>
              <ThemeProvider theme={getMuiTheme}>
                <MUIDataTable
                  data={securityData}
                  columns={SecurityColumnOnline}
                  options={{
                    search: false,
                    print: false,
                    pagination: false,
                    download: true,
                    viewColumns: false,
                    filter: false,
                    rowsPerPage: 5,
                    rowsPerPageOptions: [5, 10, 25],
                    // responsive: 'scrollMaxHeight',
                    // selectableRows: "none",
                    textLabels: {
                      body: {
                        noMatch: loading ? <CircularLoading /> : "",
                      },
                    },
                  }}
                />
                {/* <div className={classes.pricebar}>
                  <div className={classes.pricelabel}>
                    <Typography variant="body1">Total Sold Price</Typography>
                  </div>
                  <div className={classes.price}>
                    <Typography variant="body1"> {totalSoldPrice}
                      {
                        securityData[0] && securityData[0].is_tcs_tds_collected != 0 ? (
                          <>{securityData[0].is_tcs_tds_collected == 1 ? " + " : " - "} {Number.isNaN(tdsValue) ? "" : tdsValue} {securityData[0].is_tcs_tds_collected == 1 ? "TCS" : "TDS"}
                          </>

                        ) : (<></>)
                      }
                    </Typography>

                  </div>
                </div> */}
                {/* <div className={classes.pricebar}>
                  <div className={classes.pricelabel}>
                    <Typography variant="body1"> </Typography>
                  </div>
                  <div className={classes.price}>
                    <Typography variant="body1">
                      {
                        securityData[0] && securityData[0].is_tcs_tds_collected != 0 ? (
                          <>{Number.isNaN(finalOrderPrice) ? "" : finalOrderPrice}
                          </>
                        ) : (<></>)
                      }
                    </Typography>
                  </div>
                </div> */}
              </ThemeProvider>
            </>
          ) : (
            <>
              <ThemeProvider theme={getMuiTheme}>
                <MUIDataTable
                  data={securityData}
                  columns={SecurityColumn}
                  options={{
                    search: false,
                    print: false,
                    pagination: false,
                    download: true,
                    viewColumns: false,
                    filter: false,
                    rowsPerPage: 5,
                    rowsPerPageOptions: [5, 10, 25],
                    // responsive: 'scrollMaxHeight',
                    selectableRows: "none",
                    textLabels: {
                      body: {
                        noMatch: loading ? <CircularLoading /> : "",
                      },
                    },
                  }}
                />
                {(
                  <>
                      {serviceOrderData.order_type_code === "BUY" && (
                      <div className={classes.pricebar}>
                        <div className={classes.pricelabel}>
                          <Typography variant="body1">
                            Total Client Price
                          </Typography>
                        </div>
                        <div className={classes.price}>
                        <Typography variant="body1">
                          <Tooltip
                          placement="right"
                          className={classes.tooltip}
                          title={copy ? "Copied" : "Click to Copy"}
                        >
                          <span
                            variant="body1"
                            onClick={() => {
                              onHover(totalSoldPrice);
                            }}
                            onMouseLeave={onLeave}
                          >
                            {totalSoldPrice}
                        </span>
                      </Tooltip>
                      <Tooltip
                        placement="right"
                        className={classes.tooltip}
                        title={copy ? "Copied" : "Click to Copy"}
                      >
                        {securityData[0] &&
                        securityData[0].is_tcs_tds_collected !== 0 ? (
                          <span
                            onClick={() =>
                              onHover(Number.isNaN(tdsValue) ? "" : tdsValue)
                            }
                            onMouseLeave={onLeave}
                          >
                            {securityData[0].is_tcs_tds_collected === 1
                              ? " + "
                              : " - "}{" "}
                            {Number.isNaN(tdsValue) ? "" : tdsValue}{" "}
                            {securityData[0].is_tcs_tds_collected === 1
                              ? "TCS"
                              : "TDS"}
                          </span>
                        ) : (
                          <></>
                        )}
                      </Tooltip>
                      </Typography>
                        </div>
                      </div>
                    )}
                     {securityData[0] &&
                        securityData[0].is_tcs_tds_collected !== 0 &&  serviceOrderData.order_type_code === "BUY" &&    
      <div className={classes.pricebar}>
                  <div className={classes.pricelabel}>
                    <Typography variant="body1"> </Typography>
                  </div>
                   <div className={classes.price}>
                    <Tooltip
                      placement="right"
                      className={classes.tooltip}
                      title={copy ? "Copied" : "Click to Copy"}
                    >
                      <Typography
                        variant="body1"
                        onClick={() =>
                          onHover(
                            Number.isNaN(finalOrderPrice) ? "" : finalOrderPrice
                          )
                        }
                        onMouseLeave={onLeave}
                      >
                        {securityData[0] &&
                        securityData[0].is_tcs_tds_collected !== 0 ? (
                          <>
                            {Number.isNaN(finalOrderPrice)
                              ? ""
                              : finalOrderPrice}
                          </>
                        ) : (
                          <></>
                        )}
                      </Typography>
                    </Tooltip>
                  </div>
                </div>}
                   {(serviceOrderData.transaction_type_code === "INTERMEDIATE"  ||
                  serviceOrderData.description === "Partner's Client") && ( <div className={classes.pricebar}>
                      <div className={classes.pricelabel}>
                        <Typography variant="body1">
                        Total RM Commission
                        </Typography>
                      </div>
                      <div className={classes.price}>
                        <Tooltip
                          placement="right"
                          className={classes.tooltip}
                          title={copy ? "Copied" : "Click to Copy"}
                        >
                          <Typography
                            variant="body1"
                            onClick={() => {
                              onHover(totalCommissionPrice);
                            }}
                            onMouseLeave={onLeave}
                          >
                            {totalCommissionPrice}
                          </Typography>
                        </Tooltip>
                      </div>
                    </div>)}
                  </>
                )}
                
                {serviceOrderData.seller_type ===2 && ( <div className={classes.pricebar}>
                      <div className={classes.pricelabel}>
                        <Typography variant="body1">
                      Total Facilitator Commission
                        </Typography>
                      </div>
                      <div className={classes.price}>
                        <Tooltip
                          placement="right"
                          className={classes.tooltip}
                          title={copy ? "Copied" : "Click to Copy"}
                        >
                          <Typography
                            variant="body1"
                            onClick={() => {
                              onHover(totalFacilitatorCommissionPrice);
                            }}
                            onMouseLeave={onLeave}
                          >
                            {totalFacilitatorCommissionPrice}
                          </Typography>
                        </Tooltip>
                      </div>
                    </div>)}
               { (serviceOrderData.order_type_code === "BUY" && serviceOrderData.transaction_type_code === "DIRECT") ? null : <div className={classes.pricebar}>
                  <div className={classes.pricelabel}>
                    <Typography variant="body1">
                      {serviceOrderData.order_type_code === "BUY"
                        ? "Total Buy Price"
                        : "Total Sold Price"}
                    </Typography>
                  </div>
                  <div className={classes.price}>
                    <Typography variant="body1">
                      <Tooltip
                        placement="right"
                        className={classes.tooltip}
                        title={ (serviceOrderData.order_type_code === "BUY" && serviceOrderData.transaction_type_code === "INTERMEDIATE")  ? "": copy ? "Copied" :" Click to Copy"}
                      >
                        <span
                          onClick={() =>
                            onHover(
                              serviceOrderData.order_type_code === "BUY" 
                                
                                ? (serviceOrderData.transaction_type_code === "INTERMEDIATE"  ? "" : totalSoldPrice )
                                :  totalSoldPrice 
                            )
                          }
                          onMouseLeave={onLeave}
                        >
                          {serviceOrderData.order_type_code === "BUY"  ? (serviceOrderData.transaction_type_code === "INTERMEDIATE"  ? <span style={{color:'grey'}}>{buyTotalPrice()}</span> : totalSoldPrice )
                            :  totalSoldPrice }
                        </span>
                      </Tooltip>
                     {serviceOrderData.order_type_code === "SELL" && <Tooltip
                        placement="right"
                        className={classes.tooltip}
                        title={copy ? "Copied" : "Click to Copy"}
                      >
                        {securityData[0] &&
                        securityData[0].is_tcs_tds_collected !== 0 ? (
                          <span
                            onClick={() =>
                              onHover(Number.isNaN(tdsValue) ? "" : tdsValue)
                            }
                            onMouseLeave={onLeave}
                          >
                            {securityData[0].is_tcs_tds_collected === 1
                              ? " + "
                              : " - "}{" "}
                            {Number.isNaN(tdsValue) ? "" : tdsValue}{" "}
                            {securityData[0].is_tcs_tds_collected === 1
                              ? "TCS"
                              : "TDS"}
                          </span>
                        ) : (
                          <></>
                        )}
                      </Tooltip>}
                    </Typography>
                  </div>
                </div>}
                {serviceOrderData.order_type_code === "SELL" && <div className={classes.pricebar}>
                  <div className={classes.pricelabel}>
                    <Typography variant="body1"> </Typography>
                  </div>
                   <div className={classes.price}>
                    <Tooltip
                      placement="right"
                      className={classes.tooltip}
                      title={copy ? "Copied" : "Click to Copy"}
                    >
                      <Typography
                        variant="body1"
                        onClick={() =>
                          onHover(
                            Number.isNaN(finalOrderPrice) ? "" : finalOrderPrice
                          )
                        }
                        onMouseLeave={onLeave}
                      >
                        {securityData[0] &&
                        securityData[0].is_tcs_tds_collected !== 0 ? (
                          <>
                            {Number.isNaN(finalOrderPrice)
                              ? ""
                              : finalOrderPrice}
                          </>
                        ) : (
                          <></>
                        )}
                      </Typography>
                    </Tooltip>
                  </div>
                  </div>}
              </ThemeProvider>
            </>
          )}
        </div>
      </NormalCard>
     {serviceOrderData.commission_split_data  && <NormalCard title="Channel Partner Commission Split Report">
      <TableContainer  component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell>
                Channel Partner
              </TableCell>
              <TableCell>
                Commission Value
              </TableCell>
              <TableCell>
                Commission Percentage
              </TableCell>
              <TableCell>
                Commission Paid 
              </TableCell>
              <TableCell>
                Commission Paid Date
              </TableCell>
              <TableCell>
                
              </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                  {serviceOrderData.commission_split_data?.map((item,index)=>{
                    return(
                      <TableRow key={index}>
                        <TableCell>
                          {CopyTextTable(item.name)}
                        </TableCell>
                        <TableCell>
                          {CopyTextTable(item.commission_value)}
                        </TableCell>
                        <TableCell>
                          {CopyTextTable(item.commission_percentage)}
                        </TableCell>
                        <TableCell>
                          {item.commission_paid_status ? "Yes" : "No"}
                        </TableCell>
                        <TableCell>
                          {item.commission_paid_date ? moment(item.commission_paid_date).format("DD-MM-YYYY") :"NA" }
                        </TableCell>
                        {(role === "OPERATION" || role === "ADMIN") &&<TableCell>
                          <IconButton onClick={()=>handleCommissionDialog(item)}>
                            <Edit/>
                          </IconButton>
                        </TableCell>}
                      </TableRow>
                    )
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      </NormalCard>}
      {escrowResponse.length === 0 ? null : (
        <>
          <NormalCard title="ESCROW">
            <EscrowCard
              orderId={serviceOrderData}
              escrowResponse={escrowResponse}
            />
          </NormalCard>
        </>
      )}
      {serviceOrderData.payment_mode &&
      !serviceOrderData.is_purchased_online ? (
        <NormalCard title="Payment info">
          <div className={classes.induvidual}>
          <CustomTooltip  label={"Payment Date"} value={moment(serviceOrderData.payment_date).format("DD-MM-YYYY")} />
          <CustomTooltip  label={"Transaction Date"} value={moment(serviceOrderData.transaction_date).format("DD-MM-YYYY")} />
          <CustomTooltip  label={"Payment Mode"} value={serviceOrderData.payment_mode === 1
                  ? "Prepaid"
                  : serviceOrderData.payment_mode === 2
                  ? "Postpaid"
                  : ""} />

                <CustomTooltip  label={"Payment pre-approved"} value={(serviceOrderData.order_type_code === "SELL"  ?serviceOrderData.investor_prepaid_status :serviceOrderData.vendor_prepaid_status) ? "Yes" : "No"} />
         {(serviceOrderData.order_type_code === "SELL"  ?serviceOrderData.investor_prepaid_status :serviceOrderData.vendor_prepaid_status) ? <CustomTooltip  label={"Payment pre-approved range"} value={serviceOrderData.order_type_code === "SELL"  ?serviceOrderData.investor_prepaid_range :serviceOrderData.vendor_prepaid_range} /> : null}
         
          </div>
        </NormalCard>
      ) : null}
      {serviceOrderData.is_purchased_online ? null : (
        // <>
        //   <NormalCard title="Documents">
        //     <VerifiedChecklistAndDetailsOnline
        //       step={activeStep.toString()}
        //       service_order_id={serviceOrderData.id}
        //       mode={verifyModalMode}
        //       service_order_type={serviceOrderData.order_type_code}
        //       formSubmit={submitHandleVerifiedData}
        //     />
        //   </NormalCard>
        // </>
        <></>
      )}
      {serviceOrderData.is_purchased_online === true ? (
        <></>
      ) : (
        <NormalCard title="Referral Details">
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Referral Name</label>
            <p className={classes.details}>{serviceOrderData.referral}</p>
          </div>
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Referral Fee</label>
            <p className={classes.details}>
              {serviceOrderData.referral_amount === null
                ? ""
                : Number(serviceOrderData.referral_amount).toFixed(3)}
            </p>
          </div>
        </NormalCard>
      )}
      {serviceOrderData.is_custom_branding &&
      !serviceOrderData.is_purchased_online ? (
        <NormalCard title="Custom branding Image">
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>
              <img
                src={serviceOrderData.custom_branding_logo}
                width={"80%"}
                alt=""
              />
            </label>
            <p className={classes.details}></p>
          </div>
        </NormalCard>
      ) : null}
      {serviceOrderData.is_nse_trade ? (
        <NormalCard title="Mail Templates">
          <div className={classes.uploadAlign}>
            <Grid item md={12} sm={12}>
              <List dense={true}>
                {nseMailHistory &&
                  nseMailHistory.map((item, i) => (
                    <Grid container key={i} style={{ padding: "2px 0" }}>
                      <Grid item md={4} sm={12}>
                        {/* <React.Fragment> */}
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography
                                style={{
                                  maxWidth: 300,
                                  cursor: item.mail_status ? "" : "pointer",
                                }}
                                color={item.mail_status ? "" : "primary"}
                                onClick={() =>
                                  !item.mail_status && handleMails(item)
                                }
                              >
                                {item.subject}
                              </Typography>
                            }
                          />
                          <ListItemSecondaryAction>
                            <Typography style={{ maxWidth: 300 }}>
                              {item.mail_status ? (
                                <CheckCircleOutlineIcon
                                  style={{ color: "green" }}
                                />
                              ) : (
                                <CancelIcon style={{ color: "red" }} />
                              )}
                            </Typography>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                      </Grid>
                    </Grid>
                  ))}
              </List>
            </Grid>
          </div>
        </NormalCard>
      ) : null}
      {/* {

        <NormalCard title="Validate Deal term signature">
          <div className={classes.formgrgroup}>
            <Checkbox label="Valid Signature"
              checked={checked}
              onChange={handleChange}
              // onChange={(e) => {
              //   if(approve){
              //     setApprove(true)
              //     setinValidSignature(e.target.value)
              //   }else{
              //     setApprove(false)
              //     setValidSignature(e.target.value)
              //   }
              // }}
              name="Valid Signature"
              color="primary"
            />
            <label className={classes.labelname}> validate Signature</label>

          </div>
        </NormalCard>
      } */}

      <NormalCard
        title="Deal Terms"
        btn={
          role === 'ADMIN_VIEWER' ? null :
          <div style={{display:'flex',justifyContent:'end', gap:10}}>
         {serviceOrderData.seller_type ===2 && <Button
            color="primary"
            className={classes.popDownBtn}
            size="medium"
            sx={{borderRadius:'16px 0px 0px 16px !important'}}
            onClick={() => generateDealTerm()}
            variant="contained"
          >
            Generate Deal Term
          </Button>}
          <Button
            color="primary"
            className={classes.popDownBtn}
            size="medium"
            onClick={() => handleDocumentData()}
            variant="contained"
          >
            Add Documents
          </Button>
          </div>
          
        }
      >
        {dealTermFileData ? (
          <div className={classes.uploadAlign}>
            <Grid item md={12} sm={12}>
            <List dense={true}>
               {serviceOrderData.seller_type !== 2  && 
               <>
                <Grid item md={6} sm={12}>
                  <ListItem>
                    <ListItemIcon>
                      <PictureAsPdfIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          style={{ maxWidth: 300 }}
                          component={"a"}
                          noWrap={false}
                          href={`${dealTermFileData}`}
                          color="primary"
                          target="_blank"
                        >
                          Deal Term
                        </Typography>
                      }
                    ></ListItemText>
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="download"
                        title="Download"
                      >
                        <Typography
                          style={{ maxWidth: 300 }}
                          component={"a"}
                          noWrap={false}
                          href={`${dealTermFileData}`}
                          download={`Deal-term_${serviceOrderData.service_id}.pdf`}
                        >
                          <CloudDownloadIcon />
                        </Typography>
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </Grid>
                  <Divider />
               </>
                }
                {/* { serviceOrderData.is_purchased_online ? null : <><Grid item md={6} sm={12}>
                <ListItem>
                  <ListItemIcon>
                    <PictureAsPdfIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        style={{ maxWidth: 300 }}
                        component={"a"}
                        noWrap={false}
                        href={`${invoice}`}
                        color="primary"
                        target="_blank"
                      >
                        Invoice
                      </Typography>
                    }
                  ></ListItemText>
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="download"
                      title="Download"
                    >
                      <Typography
                        style={{ maxWidth: 300 }}
                        component={"a"}
                        noWrap={false}
                        href={`${invoice}`}
                        download={`Invoice_${serviceOrderData.service_id}.pdf`}
                      >
                        <CloudDownloadIcon />
                      </Typography>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </Grid>
                    // style={{ padding: (item.deal_term_document_type_code === 'PAYMENT_RECEIVED_PROOF' || item.deal_term_document_type_code === 'SHARE_TRANSFERED_PROOF') && '4px 0'}}
              <Divider /></>} */}
                {documentData &&
                  documentData.map((item, i) => (
                    <>
                      <Grid
                        container
                        key={i}
                        style={{
                          margin:
                            item.deal_term_document_type_code ===
                              "STAGE_ONE_DOCUMENT" ||
                            item.deal_term_document_type_code ===
                              "STAGE_TWO_DOCUMENT"
                              ? "12px 0"
                              : "4px 0",
                        }}
                      >
                        <Grid item md={6} sm={12}>
                          {/* <React.Fragment> */}
                          <ListItem>
                            <ListItemIcon>
                              {(item.deal_term_file_type === "image/jpg" ||
                                item.deal_term_file_type === "image/gif" ||
                                item.deal_term_file_type === "image/png" ||
                                item.deal_term_file_type === "image/jpeg") && (
                                <ImageIcon />
                              )}
                              {(item.deal_term_file_type ===
                                "application/pdf" ||
                                item.deal_term_file_type === ".pdf") && (
                                <PictureAsPdfIcon />
                              )}
                              {item.deal_term_file_type ===
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
                                <DescriptionIcon />
                              )}
                              {/* "application/vnd.openxmlformats-officedocument.wordprocessingml.document" */}
                            </ListItemIcon>

                            <ListItemText
                              button
                              primary={
                                <Typography
                                  style={{ maxWidth: 300, cursor: "pointer" }}
                                  component={"a"}
                                  noWrap={false}
                                  onClick={() => handleFileDialog(item)}
                                  // href={`${item.deal_term_file_url}`}
                                  color="primary"
                                  target="_blank"
                                >
                                  <p>
                                    {" "}
                                    {item.document_name === ""
                                      ? item.deal_term_document_type_description
                                      : item.document_name}
                                  </p>
                                </Typography>
                              }

                              // secondary={secondary ? 'Secondary text' : null}
                            />
                            {/* <ListItemText><Typography>{item.deal_term_document_type_description === "Others" ? item.document_name : <></>}</Typography></ListItemText> */}
                            <ListItemSecondaryAction>
                              {item.deal_term_document_type_description !==
                              "Signed Deal Term" && role !== 'ADMIN_VIEWER' ? (
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  title="Delete"
                                  onClick={() => {
                                    handlefileDelete(item.id);
                                  }}
                                >
                                  <Typography color="primary">
                                    <DeleteIcon />
                                  </Typography>
                                </IconButton>
                              ) : (
                                <p></p>
                              )}
                              <IconButton
                                edge="end"
                                aria-label="download"
                                title="Download"
                                // onClick={() => handleDownload(item.deal_term_file_url)}
                              >
                                <Typography
                                  style={{ maxWidth: 300 }}
                                  component={"a"}
                                  noWrap={false}
                                  onClick={() => handleDownload(item)}
                                  // href={`${item.deal_term_file_url}`}
                                  target="_blank"
                                  //   download="Deal Term.pdf"
                                >
                                  <CloudDownloadIcon />
                                </Typography>
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>

                          {/* </React.Fragment> */}
                        </Grid>
                        <Grid item md={6} sm={12}>
                          <div
                            style={{
                              // marginTop:item.deal_term_document_type_code === 'STAGE_ONE_DOCUMENT' || item.deal_term_document_type_code === 'STAGE_TWO_DOCUMENT' ?'-10px' :'-4px',
                              fontSize: "0.6rem",
                              textAlign: "left",
                            }}
                          >
                            {(item.deal_term_document_type_code ===
                              "STAGE_ONE_DOCUMENT" ||
                              item.deal_term_document_type_code ===
                                "STAGE_TWO_DOCUMENT") && (
                              <>
                                <i style={{ display: "inline-flex" }}>
                                  {" "}
                                  Amount :{" "}
                                  {CopyText(item.document_reference_id)}{" "}
                                </i>
                                <br />
                                <i style={{ display: "inline-flex" }}>
                                  {" "}
                                  Payment Date :{" "}
                                  {moment(item.document_reference_date).format(
                                    "DD-MM-YYYY"
                                  )}{" "}
                                </i>
                              </>
                            )}

                            {(item.deal_term_document_type_code ===
                              "PAYMENT_RECEIVED_PROOF" ||
                              item.deal_term_document_type_code ===
                                "SHARE_TRANSFERED_PROOF") && (
                              <i style={{ display: "inline-flex" }}>
                                Reference Id :{" "}
                                {CopyText(item.document_reference_id)}{" "}
                              </i>
                            )}
                            <br></br>
                            <i>Uploaded by : {item.name ? item.name: serviceOrderData.order_type_code === "SELL"
                ? serviceOrderData.investor
                : serviceOrderData.vendor}</i>
                            <br></br>
                            <i>
                              Uploaded on :{" "}
                              {moment(item.create_datetime).format(
                                "DD-MM-YYYY LTS"
                              )}
                            </i>
                          </div>
                        </Grid>
                      </Grid>
                      <Divider />
                    </>
                  ))}
              </List>
            </Grid>
          </div>
        ) : (
          <Typography style={{ margin: "1rem 1rem 0" }}>Loading...</Typography>
        )}
      </NormalCard>
      {!open && (
        <NormalCard title="Remarks">
              <Formik
                enableReinitialize={true}
                initialValues={initialData}
                validationSchema={Yup.object().shape({
                  remarks: Yup.string(),
                })}
                onSubmit={(values) => {
                  updateRemarks(values);
                }}
              >
                {({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <Field
                      name="remarks">
                      {({ field, form }) => {
                        return (
                          <>
                         <CKEditor
                              editor={ClassicEditor}
                              data={field?.value}
                              config={{
                                toolbar: [
                                  "heading",
                                  "|",
                                  "bold",
                                  "italic",
                                  "blockQuote",
                                  "numberedList",
                                  "bulletedList",
                                  "insertTable",
                                  "|",
                                  "undo",
                                  "redo",
                                ],
                              }}
                              onChange={(e, editor) => {
                                form.setFieldValue("remarks", editor.getData());
                              }}
                            />
                          </>
                        );
                      }}
                    </Field>
                    <Button
                      style={{
                        margin: '10px 0px',
                        textTransform:'none'
                      }}
                      disabled={role ==='ADMIN_VIEWER'}
                      color="primary"
                      // className={classes.popDownBtn}
                      size="medium"
                      type="submit"
                      variant="contained"
                    >
                      Update Remarks
                    </Button>
                  </form>
                )}
              </Formik>
        
        </NormalCard>
      )}
      {serviceOrderData.is_purchased_online ? (
        <></>
      ) : (
        <>
          <NormalCard
            title="Verified Details"
            btn={
             role==='ADMIN_VIEWER' ? null : <Button
                color="primary"
                className={classes.popDownBtn}
                size="medium"
                onClick={() => handleEditVerificationModal()}
                variant="contained"
              >
                Edit Verified Details
              </Button>
            }
          >
            {verifiedDetailsData?.is_client_details_verified ? (
              <div className={classes.verified}>
                {verifiedDetailsData.is_client_details_verified
                  .last_updated_user !== null ? (
                  <div className={classes.verified_item}>
                    <Typography color="primary">
                      {verifiedDetailsData.is_client_details_verified.value ===
                      true ? (
                        <>
                          <CheckIcon
                            className={classes.check}
                            color="primary"
                            size="small"
                          />{" "}
                          Client Details
                        </>
                      ) : (
                        <>
                          <ClearIcon
                            className={classes.unCheck}
                            color="primary"
                            size="small"
                          />{" "}
                          Client Details
                        </>
                      )}
                    </Typography>
                    <Typography
                      className={classes.shortdetails}
                      variant="body1"
                    >
                      {verifiedDetailsData.is_client_details_verified
                        .last_updated_user !== null
                        ? `By ${
                            verifiedDetailsData.is_client_details_verified
                              .last_updated_user
                          }  ${moment(
                            verifiedDetailsData.is_client_details_verified
                              .last_updated_datetime
                          ).format("DD-MM-YYYY LTS")} `
                        : ""}
                    </Typography>
                  </div>
                ) : (
                  <></>
                )}
                {verifiedDetailsData.is_demat_account_verified
                  .last_updated_user !== null ? (
                  <div className={classes.verified_item}>
                    <Typography color="primary">
                      {verifiedDetailsData.is_demat_account_verified.value ===
                      true ? (
                        <>
                          <CheckIcon
                            className={classes.check}
                            color="primary"
                            size="small"
                          />{" "}
                          Demat Account
                        </>
                      ) : (
                        <>
                          <ClearIcon
                            className={classes.unCheck}
                            color="primary"
                            size="small"
                          />{" "}
                          Demat Account
                        </>
                      )}
                    </Typography>
                    <Typography
                      className={classes.shortdetails}
                      variant="body1"
                    >
                      {verifiedDetailsData.is_demat_account_verified
                        .last_updated_user !== null
                        ? `By ${
                            verifiedDetailsData.is_demat_account_verified
                              .last_updated_user
                          }  ${moment(
                            verifiedDetailsData.is_demat_account_verified
                              .last_updated_datetime
                          ).format("DD-MM-YYYY LTS")} `
                        : ""}{" "}
                    </Typography>
                  </div>
                ) : (
                  <></>
                )}
                {verifiedDetailsData
                  .is_security_recieved_or_transferred_verified
                  .last_updated_user !== null ? (
                  <div className={classes.verified_item}>
                    <Typography color="primary">
                      {verifiedDetailsData
                        .is_security_recieved_or_transferred_verified.value ===
                      true ? (
                        <>
                          <CheckIcon
                            className={classes.check}
                            color="primary"
                            size="small"
                          />{" "}
                          Scrips{" "}
                          {serviceOrderData.order_type_code === "SELL"
                            ? "Transferred"
                            : "Received"}
                        </>
                      ) : (
                        <>
                          <ClearIcon
                            className={classes.unCheck}
                            color="primary"
                            size="small"
                          />{" "}
                          Scrips{" "}
                          {serviceOrderData.order_type_code === "SELL"
                            ? "Transferred"
                            : "Received"}
                        </>
                      )}
                    </Typography>
                    <Typography
                      className={classes.shortdetails}
                      variant="body1"
                    >
                      {verifiedDetailsData
                        .is_security_recieved_or_transferred_verified
                        .last_updated_user !== null
                        ? `By ${
                            verifiedDetailsData
                              .is_security_recieved_or_transferred_verified
                              .last_updated_user
                          }  ${moment(
                            verifiedDetailsData
                              .is_security_recieved_or_transferred_verified
                              .last_updated_datetime
                          ).format("DD-MM-YYYY LTS")} `
                        : ""}
                    </Typography>
                    <Typography
                      className={classes.shortdetails}
                      variant="body1"
                    >
                      {verifiedDetailsData.security_transaction_id.value ===
                      null
                        ? `Scrip ${
                            serviceOrderData.order_type_code === "SELL"
                              ? "Transferred"
                              : "Received"
                          } Id :`
                        : `Scrip ${
                            serviceOrderData.order_type_code === "SELL"
                              ? "Transferred"
                              : "Received"
                          } Id : ${
                            verifiedDetailsData.security_transaction_id.value
                          }`}{" "}
                    </Typography>
                  </div>
                ) : (
                  <></>
                )}
                {verifiedDetailsData.is_payment_recieved_or_transferred_verified
                  .last_updated_user !== null ? (
                  <div className={classes.verified_item}>
                    <Typography color="primary">
                      {verifiedDetailsData
                        .is_payment_recieved_or_transferred_verified.value ===
                      true ? (
                        <>
                          <CheckIcon
                            className={classes.check}
                            color="primary"
                            size="small"
                          />{" "}
                          {serviceOrderData.order_type_code === "SELL"
                            ? "Payment Received"
                            : "Payment Transferred"}
                        </>
                      ) : (
                        <>
                          <ClearIcon
                            className={classes.unCheck}
                            color="primary"
                            size="small"
                          />{" "}
                          {serviceOrderData.order_type_code === "SELL"
                            ? "Payment Received"
                            : "Payment Transferred"}
                        </>
                      )}
                    </Typography>
                    <Typography
                      className={classes.shortdetails}
                      variant="body1"
                    >
                      {verifiedDetailsData
                        .is_payment_recieved_or_transferred_verified
                        .last_updated_user !== null
                        ? `By ${
                            verifiedDetailsData
                              .is_payment_recieved_or_transferred_verified
                              .last_updated_user
                          }  ${moment(
                            verifiedDetailsData
                              .is_payment_recieved_or_transferred_verified
                              .last_updated_datetime
                          ).format("DD-MM-YYYY LTS")} `
                        : ""}
                    </Typography>
                    <Typography
                      className={classes.shortdetails}
                      variant="body1"
                    >
                      {verifiedDetailsData.payment_transaction_id.value === null
                        ? serviceOrderData.order_type_code === "SELL"
                          ? `Payment Received Id :`
                          : `Payment Transferred Id :`
                        : `Payment ${
                            serviceOrderData.order_type_code === "SELL"
                              ? " Received"
                              : "Transferred"
                          } Id : ${
                            verifiedDetailsData.payment_transaction_id.value
                          }`}{" "}
                    </Typography>
                  </div>
                ) : (
                  <></>
                )}

                {verifiedDetailsData.is_signed_deal_term_uploaded_verified
                  .last_updated_user !== null ? (
                  <div className={classes.verified_item}>
                    <Typography color="primary">
                      {verifiedDetailsData.is_signed_deal_term_uploaded_verified
                        .value === true ? (
                        <>
                          <CheckIcon
                            className={classes.check}
                            color="primary"
                            size="small"
                          />{" "}
                          Signed Deal Term Uploaded
                        </>
                      ) : (
                        <>
                          <ClearIcon
                            className={classes.unCheck}
                            color="primary"
                            size="small"
                          />{" "}
                          Signed Deal Term Uploaded
                        </>
                      )}
                    </Typography>
                    <Typography
                      className={classes.shortdetails}
                      variant="body1"
                    >
                      {verifiedDetailsData.is_signed_deal_term_uploaded_verified
                        .last_updated_user !== null
                        ? `By ${
                            verifiedDetailsData
                              .is_signed_deal_term_uploaded_verified
                              .last_updated_user
                          }  ${moment(
                            verifiedDetailsData
                              .is_signed_deal_term_uploaded_verified
                              .last_updated_datetime
                          ).format("DD-MM-YYYY LTS")} `
                        : ""}
                    </Typography>
                  </div>
                ) : (
                  <></>
                )}
                {verifiedDetailsData.is_shared_credit_confirmed_verified
                  .last_updated_user !== null ? (
                  <div className={classes.verified_item}>
                    <Typography color="primary">
                      {verifiedDetailsData.is_shared_credit_confirmed_verified
                        .value === true ? (
                        <>
                          <CheckIcon
                            className={classes.check}
                            color="primary"
                            size="small"
                          />{" "}
                          Share Credit Confirmed
                        </>
                      ) : (
                        <>
                          <ClearIcon
                            className={classes.unCheck}
                            color="primary"
                            size="small"
                          />{" "}
                          Share Credit Confirmed
                        </>
                      )}
                    </Typography>
                    <Typography
                      className={classes.shortdetails}
                      variant="body1"
                    >
                      {verifiedDetailsData.is_shared_credit_confirmed_verified
                        .last_updated_user !== null
                        ? `By ${
                            verifiedDetailsData
                              .is_shared_credit_confirmed_verified
                              .last_updated_user
                          }  ${moment(
                            verifiedDetailsData
                              .is_shared_credit_confirmed_verified
                              .last_updated_datetime
                          ).format("DD-MM-YYYY LTS")} `
                        : ""}{" "}
                    </Typography>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : null}
          </NormalCard>
        </>
      )}
            <DashboardLogs id={useParamsId} typeId={3} title="Order History"/>
      <>
        <SessionExpiredModal open={openSessionExpiredModal} />

        <ModalCentered
          title={titleModal}
          open={openModal}
          maxWidth={modalType === "mail" ? 'md' :'sm'}
          toggle={handleModalClose}
          children={
            modalType === "Documents" ? (
              <DocumentUploadForm
                formSubmit={submitHandleDocumentModalData}
                order_type={serviceOrderData.order_type}
                isNSE={serviceOrderData.is_nse_trade}
                service_order_id={serviceOrderData.id}
                is_purchased_online={serviceOrderData.is_purchased_online}
              />
            ) : modalType === "mail" ? (
              <MailTemplate
                formSubmit={submitMailTemplate}
                template={template}
                order_id={serviceOrderData.id}
              />
            ) : modalType === "Commission" ? (
              <CommissionStatus
                formSubmit={submitCommissionStatus}
                order_id={serviceOrderData.id}
                data={{status:serviceOrderData.commission_paid,date:serviceOrderData.commission_paid_date}}
              />
            ) :modalType === "Generate" ? (
              <GenerateDealTerm
                formSubmit={generateDealTermForFacilitator}
                order_type={serviceOrderData.order_type}
              />
            ) :
             ( <VerifiedChecklistAndDetails
                step={activeStep.toString()}
                service_order_id={serviceOrderData.id}
                mode={verifyModalMode}
                service_order_type={serviceOrderData.order_type_code}
                formSubmit={submitHandleVerifiedData}
              />
            )
          }
        />

        <ModalCentered
          title={"Payment proof"}
          open={imageDialog}
          fullScreen={true}
          toggle={handleModalClose}
          children={
            imageUrl ? (
              <div style={{ textAlign: "center" }}>
                {imageType === "image" ? (
                  <ImageRotate alt="cool image" image={imageUrl} />
                ) : (
                  <iframe
                  title="url"
                    src={imageUrl}
                    alt=""
                    style={{ width: "100%", height: "70vh" }}
                  />
                )}
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>Loading...</div>
            )
          }
        />

        {serviceOrderData.is_purchased_online ? (
          <>
            <div className={classes.btnright}>
              <Button
                onClick={() => {
                  setCompleteOrderModel(true);
                }}
                color="primary"
                variant="contained"
                disabled={
                  serviceOrderData.order_status_new_display !==
                  "Share Transfer Completed"
                }
                style={{ textTransform: "none", textAlign: "center" }}
              >
                COMPLETE ORDER
              </Button>
            </div>
          </>
        ) : (
          <></>
        )}
        <ModalCentered
          title={url.title ? url.title : "Loading..."}
          fullScreen={true}
          open={show}
          toggle={() => {
            setShow(false);
            setUrl(false);
          }}
          children={
            url.url ? (
              <div style={{ textAlign: "center", position: "relative" }}>
                <Grid
                  container
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Grid item xs={12} md={10}>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {url.isPdf ? (
                        <iframe
                        title="frame"
                          style={{
                            width: "100%",
                            height: "calc(100vh - 100px)",
                          }}
                          src={url.url}
                        />
                      ) : (
                        <ImageRotate
                          alt="cool image"
                          type="true"
                          image={url && url.url}
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>
                <br />
              </div>
            ) : (
              <div>Loading...</div>
            )
          }
        />

        <Dialog
          open={completeOrderModel}
          onClose={() => {
            setCompleteOrderModel(false);
          }}
          toggle={handleModalCloseComplete}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            <Typography
              style={{
                backgroundColor: "#314B8F",
                padding: 12,
                color: "#fff",
                border: "1px solid #324c90",
                width: "max-content",
                fontSize: "14px",
                fontWeight: 500,
                borderRadius: "0 0  10px 0",
                marginTop: "-20px",
                marginLeft: "-25px",
              }}
            >
              Complete Order
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to complete the order?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={updateOrderStatus}
              color="primary"
              variant="contained"
              disabled={isDisabled}
              style={{ textTransform: "none", textAlign: "center", margin: 10 }}
            >
              {isDisabled ? "Loading" : "Yes"}
            </Button>
            <Button
              onClick={() => {
                setCompleteOrderModel(false);
              }}
              color="primary"
              variant="contained"
              style={{ textTransform: "none", textAlign: "center" }}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>

        <ModalCentered
          title={titleModal}
          open={openEditModal}
          toggle={handleEditModalClose}
          children={
            modalType === "updateStatus" ? (
              <UpdateStatusForm
                partner={
                  serviceOrderData.description === "Partner's Client" ||
                  serviceOrderData.description === "Partner's RM" ||
                  serviceOrderData.description === "Partner Admin"
                    ? true
                    : false
                }
                formSubmit={submitUpdatedStatus}
                isSigned={serviceOrderData?.order_status_new_display === "Deal Term Pending" && isSignedFileExist}
                orderType={serviceOrderData.is_purchased_online}
                status={serviceOrderData.order_status_code}
                isNSE={serviceOrderData.is_nse_trade}
                service_order_id={serviceOrderData.id}
              />
            ) : modalType === "updateAssign" ? (
              <UpdateAssignForm
                formSubmit={submitUpdatedAssign}
                assign={serviceOrderData.assign}
                service_order_id={serviceOrderData.id}
              />
            ) : (
              <MappingServiceOrderForm
                formSubmit={submitMappingServiceOrder}
                service_order_id={useParamsId}
                requiredQuantity={requiredQuantity}
                serviceOrderType={serviceOrderData.order_type_code}
                securityId={securityId}
              />
            )
          }
        />
         <ModalCentered
          title={"Update commission status"}
          open={commissionStatusDialog.open}
          toggle={handleEditModalClose}
          children={
            <CommissionStatusForIndividual item={commissionStatusDialog.item} formSubmit={handleCommissionUpdate}/>
          }
        />

        <ModalCentered
          maxWidth={'md'}
          title={"Request order edit"}
          open={salesEditDialog}
          toggle={handleEditModalClose}
          children={
            <SalesOrdeUpdate formSubmit={handleRequestEdit}/>
          }
        />
        <SnackBar
          open={snackBarOpen}
          severity={severity}
          close={handleClose}
          snackbarTitle={snackbarTitle}
        />

        <AlertModal
          title="Are you sure you want to delete this document?"
          open={deleteConfirm}
          toggle={() => setDeleteConfirm(false)}
          onConfirm={deleteDocument}
          openLoading={spinner}
        ></AlertModal>
      </>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Page>
    </Root>
  );
};

const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};
export default connect(mapStateToProps, null)(ServiceOrderDetails);
