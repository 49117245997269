import axios from 'axios'
import { helpers } from 'src/_helpers'


// axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.token.slice(4, store.state.token.length);

//default header and api endpoint config
export const getLookUpDataService = {
    getLookUpData,
    getLookUpUser,
    getLookSecurity,
    getLookUpChannelPartner,
    getAvailableSecurityQuantity,
    getServiceOrderDocumentLookUp,
    getRolesByUserRole,
    getLookupFeeType,
    getLastSecurityIndex,
    getRMDetails,
    getObjectUrl,
    getKycUrl,
    getMailTemplates
};

function getLookUpData(LookData) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/lookup_table/?table=${LookData}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getLookUpUser(user_roles) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/users_by_role/?user_roles=${user_roles}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


function getLookSecurity() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/getsecuritylist`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


function getLookupFeeType(LookData) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/lookup_table/?table=${LookData}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getAvailableSecurityQuantity(securityId){
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/service_order/available_security_quantity?security_id=${securityId}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


function getLookUpChannelPartner() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/user_group/reporting_channelpartner`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getServiceOrderDocumentLookUp() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/lookup_table/?table=service_order_doc_type`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getRolesByUserRole(){
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/role_lookups_hierarchy`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


function getLastSecurityIndex(){
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/getLastSortIndexValue`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getRMDetails(id){
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/getRmDetailsByUserId?id=${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getObjectUrl(id){
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/getPresignedUrlByFileID/?id=${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getKycUrl(url) {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/getPresignedUrlKYCdocs`, JSON.stringify({ url: url}), requestOptions)
        .then(res => res)
        .catch(error => error.response)
}

function getMailTemplates(){
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/getAllNESMailTemplates`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

