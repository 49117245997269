import React, { useState } from 'react'
import IconButton from "@mui/material/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CheckIcon from '@mui/icons-material/Check';


function CopytoClipboard({userBasicInfo,state}) {
    const [icon, setIcon] = useState(true);

function selectText(info) {
  setIcon(false)
  navigator.clipboard.writeText(info)
}

  return (
    

    <IconButton
      color="primary"
      style={{
        marginLeft: "10px",
        width: "25px",
        height: "20px",
      }}
      onClick={() => {
        selectText(userBasicInfo); 
      }}
    ><FileCopyIcon
            style={{
             display: icon ? "block" : "none", fontSize: "15px"}}
           
         />
         <CheckIcon
           style={{
             display: icon ? "none" : "block", fontSize: "15px"}}
       />
    </IconButton>
  )
}

export default CopytoClipboard