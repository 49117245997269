import axios from 'axios'
import { helpers } from 'src/_helpers'

let cancelToken = axios.CancelToken;
let cancel;


 
export const serviceOrderService = {
    getServiceOrder,
    getOnlineServiceOrder,
    getLatestServiceOrder,
    getServiceOrderByID,
    postServiceOrder,
    getSecurityByID,
    getVerifiedData,
    putVerifiedData,
    putAssigToOrder,
    putStatusOfServiceOrder,
    postServiceOrderDocuments,
    getServiceOrderDocuments,
    deleteServiceOrderDocuments,
    getServiceOrderdDealTermDocuments,
    putServiceOrderData,
    postMappingServiceOrder,
    getServiceOrderByClientIdAndSecurityId,
    getServiceOrderByUserID,
    updateDealTermSignatureStauts,
    getInvoiceDocument,
    updateRemarks,
    exportOrders,
    generateMIS,
    deleteServiceOrder,
    getAllPartnerOrder,
    getDeletedOrders,
    recoverOrders,
    uploadBrandingImage,
    updateIndividualSeviceOrder,
    getFutureOrders,
    updateFutureOrderTaxValue,
    getInvoiceOrders,
    getOrderDetails,
    generateInvoicePDF,
    generateDealTerm,
    getNseMailHistory,
    sendNseEmail,
    getEscrowDetailsOfServiceOrder,
    updateCommissionStatus,
    getVouchReport,
    getCommissionData,
    commissionUpdate,
    requestOrderUpdate,
    putStatusOfOnlineServiceOrder,
    deleteProcuredBy,
    getDocumentData,
    getDocumentId
};


async function getServiceOrder() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/service_order`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function getAllPartnerOrder() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/getAllPartnerOrder`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function getOnlineServiceOrder(data) {
    
    if (cancel) {
        cancel(); // Cancel the request
      }
    const source = cancelToken.source();
    cancel = source.cancel; 
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/service_all_order`,data, { cancelToken: source.token }, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


async function getServiceOrderByUserID(userid) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/service_order_by_userid/${userid}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}




async function getLatestServiceOrder() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/dashboard/latest_service_order_status`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function getServiceOrderByID(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/service_order/${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


async function postServiceOrder(PostServiceOrderData) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/service_order`,
        JSON.stringify(PostServiceOrderData), requestOptions)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}

async function postMappingServiceOrder(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/service_order/sell_buy_order_map`,
        JSON.stringify(data), requestOptions)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}


async function postServiceOrderDocuments(PostServiceOrderDocumentData) {
    helpers.setHeader();
    return axios.post(`/service_order_document`,
        PostServiceOrderDocumentData)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}



async function getSecurityByID(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/service_order/security_list?service_order_id=${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function getVerifiedData(service_order_id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/service_order/verify_detail?service_order_id=${service_order_id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function putVerifiedData(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/service_order/verify_detail`,JSON.stringify(data), requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


async function putAssigToOrder(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/set_assign_to_order`,JSON.stringify(data), requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


async function putStatusOfServiceOrder(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/service_order/status`,JSON.stringify(data), requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function putStatusOfOnlineServiceOrder(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/service_order/online_status`,JSON.stringify(data), requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


async function getServiceOrderDocuments(service_order_id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/service_order_document/${service_order_id}`, requestOptions)
        .then(res => res)
        .catch(error => error.response)
}


async function deleteServiceOrderDocuments(service_order_id){
    helpers.setHeader();
    return axios.delete(`/service_order_document_file/${service_order_id}`)
        .then(res => {            
            return res
        })
        .catch(error => {           
            return error.response
        })
}

async function deleteServiceOrder(service_order_id){
    helpers.setHeader();
    return axios.post(`/deleteServiceOrder/${service_order_id}`)
        .then(res => {            
            return res
        })
        .catch(error => {           
            return error.response
        })
}

async function getServiceOrderdDealTermDocuments(service_order_id) {
    helpers.setHeader();
    const requestOptions = {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
    
        }
    };
    return axios.get(`/deal_term_server_order/${service_order_id}`, requestOptions)
        .then(res => {
            return res})
        .catch(error => error.response)
}

async function putServiceOrderData(serviceOrderData) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/service_order`,
        JSON.stringify(serviceOrderData), requestOptions)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}

async function getServiceOrderByClientIdAndSecurityId(security_id,client_id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/service_order_by_security/?security_id=${security_id}&client_id=${client_id}`, requestOptions)
        .then(res => res)
        .catch(error => error.response)
}


async function getInvoiceDocument(order_id) {
    helpers.setHeader();
    const requestOptions = {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/invoice_server_order/${order_id}`, requestOptions)
        .then(res => res)
        .catch(error => error.response)
}

async function updateDealTermSignatureStauts(investor_code,data,s1) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/updateDealTermSignatureStauts/?service_order_id=${data}&status=${s1}&investor_code=${investor_code}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}




async function updateRemarks(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/update_orderRemarks`,
        JSON.stringify(data), requestOptions)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}

async function exportOrders(data) {
    helpers.setHeader();
    return axios.post(`/sendOrderDetailsToEmail`,
    data)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}

async function generateMIS(data) {
    helpers.setHeader();
    return axios.post(`/generateMIS`,
    data)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}


async function getDeletedOrders() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/getDeletedOrders`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function recoverOrders(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/recoverServiceOrder/${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function uploadBrandingImage(formData) {
    helpers.setHeader();
    return axios.post(`/uploadCustomBranding`,
    formData)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}

function updateIndividualSeviceOrder(data){
    helpers.setHeader();
    return axios.post(`/updateOrderTransaction`,data)
        .then(res => {            
            return res
        })
        .catch(error => {           
            return error.response
        })
}


async function getFutureOrders(order_id,user_id,order_type) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/getNextServiceOrders?id=${order_id}&investor_id=${user_id}&order_type=${order_type}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function updateFutureOrderTaxValue(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/updateTcsOrTdsValues`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function getInvoiceOrders(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/getOrdersForInvoice`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function getOrderDetails(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/geInvoiceDatafromOrderId`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


async function generateInvoicePDF(data) {
    helpers.setHeader();
    const requestOptions = {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/generateInvoiceFromJSon`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function getNseMailHistory(order_id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/getNseMailSentHistory/?order_id=${order_id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function sendNseEmail(data) {
    helpers.setHeader();
    return axios.post(`/sendNseEmailsById`,data)
        .then(data => data)
        .catch(error => error.response)
}


async function getEscrowDetailsOfServiceOrder(id) {
    helpers.setHeader();
    return axios.post(`/getEscrowDetailsOfServiceOrder/?service_order_id=${id}`)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}

async function updateCommissionStatus(data) {
    helpers.setHeader();
    return axios.post(`/updateCommissionStatusAndDate`,data)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}

async function getVouchReport(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/exportVouchReport`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function getDocumentData(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/getDocumentData`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function getDocumentId(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/getDocumentId`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}




async function getCommissionData(payload) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/getbulkCommissionData`,payload, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


async function commissionUpdate(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/bulkCommissionUpdate`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


async function requestOrderUpdate(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/requestForOrderUpdate`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function generateDealTerm(data) {
    helpers.setHeader();
    const requestOptions = {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/generateClientSpecificDealterm`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function deleteProcuredBy(procuredBy_id){
    helpers.setHeader();
    return axios.delete(`/delete_procuredby/${procuredBy_id}`)
        .then(res => {            
            return res
        })
        .catch(error => {           
            return error.response
        })
}