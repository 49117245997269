// import 'react-perfect-scrollbar/dist/css/styles.css';
import React,{useEffect,useState} from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import GlobalStyles from 'src/components/GlobalStyles';
// import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import { Provider } from 'react-redux';
import store from 'src/store/configureStore';

import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import axios from 'axios'

// const store = configureStore();
let persistor = persistStore(store);


const App = () => {
  const routing = useRoutes(routes);
  const [isLoading,setIsloading] = useState(true)
  useEffect(()=>{
    fetch('/config.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }
    ).then((response)=>{
    return response.json();
    }).then((data)=>{
      axios.defaults.baseURL = data.ApiEndPoint
      localStorage.setItem('DefaultUnlistedAccount',JSON.stringify(data.DefaultUnlistedAccount))
      setIsloading(false)
    })
    
  
 

  },[])

    return (
      !isLoading ?  (<Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>

          <ThemeProvider theme={theme}>
            <GlobalStyles />
            {routing}
          </ThemeProvider>
        </PersistGate>
      </Provider>):(<></>)
    );
 


};

export default App;
