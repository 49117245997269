import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";
import { Container, TextField, Grid, Typography, } from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import moment from "moment"
import Autocomplete from "@mui/material/Autocomplete";
import { getLookUpDataService } from 'src/_services/lookUp'
import { additionalTradeService } from 'src/_services/additionalTrade';
import { connect } from 'react-redux';
import loadingSpinner from 'src/images/loading-spinner.gif'

import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"

const PREFIX = 'AdditionalTradeForm';

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  paddingInput: `${PREFIX}-paddingInput`,
  buttonProgress: `${PREFIX}-buttonProgress`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    justifyItems: "center",
    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.paddingInput}`]: {
    padding: "0px 0px",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: '4px'
  }
}));

function AdditionalTradeCreateForm(props) {


  const [loading, setLoading] = React.useState(false)
  const [selectVendorLookUp, setSelectVendorLookUp] = useState([]);
  const [ownedByLookUp, setOwnedByLookUp] = useState([]);
  const [securityByLookUp, setSecurityLookUp] = useState([]);
  const tomorrowDate = moment().add(1, 'days').format('YYYY-MM-DD')
  const todayDate= moment().format('YYYY-MM-DD')
  const [additionInilValue, setAdditionInilValue] = React.useState({
    type: props.type,
    security: "",
    validUpTo: tomorrowDate,
    vender: "",
    price: "",
    quantity: "",
    ownedBy: { id: props.authReducer.auth.user.user_unique_code, label: props.authReducer.auth.user.display_name },
  });
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)





  var currentDate = moment(new Date()).format("DD-MM-YYYY");
   
    // get details id
    useEffect(()=>{ 
      if(props.mode === "edit"){    
        additionalTradeService.getAdditionalTradeById(props.id).then(res=>{   
        if(res){
          if(res.status===200){  
            let initalValue= {
            type:res.data[0].requirement_type || null,
            security:{id: res.data[0].security_id, label:res.data[0].security_name},
            validUpTo:res.data[0].vdate || null,
            vender:{id: res.data[0].vendor_code, label:res.data[0].vendor},
            price:res.data[0].price || null,
            quantity:res.data[0].quantity || null,
            ownedBy: {id: res.data[0].owners_code, label:res.data[0].owners},
          }        
          setAdditionInilValue(initalValue)
          }
        }
      })
    }
  }, [])


  //  lookup data
  useEffect(() => {

    // security 
    getLookUpDataService.getLookSecurity()
      .then(res => {

        if (res) {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true)
          }

          if (res.status === 200) {

            setSecurityLookUp(res.data.map(data => {


              return { id: data.id, label: data.name }
            }))
          }
        }
      })

    // owner
    getLookUpDataService.getLookUpUser("SALES,OPERATION")
      .then(res => {
        if (res) {
          if (res.status === 200) {
            setOwnedByLookUp(res.data.map(data => {
              return { id: data.user_code, label: `${data.user_name} ` }
            }))
          }
        }
      })

    // vendor
    getLookUpDataService.getLookUpUser("CHANNEL_PARTNER")
      .then(res => {

        if (res) {
          if (res.status === 200) {
            setSelectVendorLookUp(res.data.map(data => {
              return { id: data.user_code, label: `${data.user_name} ` }
            }))
          }
        }
      })

  }, [])

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialValues={additionInilValue}
        validationSchema={Yup.object().shape({
          security: Yup.string().nullable().required("Required"),
          validUpTo: Yup.string().nullable().required("Required"),

          quantity: Yup.string().nullable().required("Required")
          .test('test less than', 'Quantity must be greater than 0', value => value > 0 ),

          ownedBy: Yup.string().nullable().required("Required"),

          price:Yup.number().positive().nullable().required("Required"),  
          vender: Yup.string().when("type", {
            is: "AVAILABILITY",
            then: Yup.string().nullable().required("Required"),
            otherwise: Yup.string(),
          }),
        })}
        onSubmit={(values) => {
          values.price = parseFloat(Number(values.price).toFixed(2))
          setLoading(true)
          props.formSubmit(values)
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div>

            </div>
            <Container className={classes.container}>
              <Typography
                color="primary"
                variant="h4"
                style={{ marginBottom: 16, textAlign: "Right" }}>
                {currentDate}
              </Typography>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Autocomplete
                    options={securityByLookUp}
                    classes={{ option: classes.option }}
                    autoHighlight
                    id="securityName"
                    getOptionLabel={(option) => option.label ||  "test"}
                    value={values.security}
                    onChange={(e, value) => {
                      setFieldValue("security", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className={classes.inputMargin}
                        {...params}
                        fullWidth={true}
                        label="Security*"
                        variant="outlined"
                        name="security"
                        error={Boolean(touched.security && errors.security)}
                        helperText={touched.security && errors.security}
                        autoComplete="off"
                      />
                    )}
                  />
                  <ErrorFocus />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      className={classes.inputMargin}
                      variant="outlined"
                      label="Valid Upto *"
                      id="validUpTo"
                      type="date"

                      step=".01"
                      placeholder=""
                      name="validUpTo"
                      // maxDate="26/03/2021"
                      onBlur={handleBlur}
                      InputProps={{ inputProps: { min: todayDate, } }}
                      // InputLabelProps={{
                      //   readOnly: true,
                      //   shrink: true
                      // }}
                      onChange={handleChange}

                      fullWidth
                      value={values.validUpTo}
                      error={Boolean(touched.validUpTo && errors.validUpTo)}
                      helperText={touched.validUpTo && errors.validUpTo}
                      aria-describedby="outlined-weight-helper-text"
                      autoComplete="off"
                    />
                    <ErrorFocus />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      className={classes.inputMargin}
                      variant="outlined"
                      label="Quantity*"
                      id="quantity"
                      name="quantity"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                      value={values.quantity}
                      error={Boolean(touched.quantity && errors.quantity)}
                      helperText={touched.quantity && errors.quantity}
                      aria-describedby="outlined-weight-helper-text"
                      autoComplete="off"
                      onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                    />
                    <ErrorFocus />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Autocomplete
                      id="ownedBy"
                      options={ownedByLookUp}
                      getOptionLabel={(option) => option.label ||  "test"}
                      value={values.ownedBy}
                      onChange={(e, value) => {
                        setFieldValue("ownedBy", value);
                      }}
                      classes={{
                        option: classes.option,
                      }}
                      autoHighlight
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth={true}
                          label="Owned By*"
                          variant="outlined"
                          name="ownedBy"
                          error={Boolean(
                            touched.ownedBy &&
                            errors.ownedBy
                          )}
                          helperText={touched.ownedBy && errors.ownedBy}
                        />
                      )}
                    />
                    <ErrorFocus />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      className={classes.inputMargin}
                      variant="outlined"
                      label="Price*"
                      id="price"
                      min="0"
                      type="number"
                      name="price"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                      value={values.price}
                      error={Boolean(touched.price && errors.price)}
                      helperText={touched.price && errors.price}
                      aria-describedby="outlined-weight-helper-text"
                      autoComplete="off"
                    />
                    <ErrorFocus />
                  </Grid>
                </Grid>

                {props.type === "AVAILABILITY" ?
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Autocomplete
                      options={selectVendorLookUp}
                      classes={{
                        option: classes.option,
                      }}
                      value={values.vender}
                      autoHighlight
                      id="vender"
                      getOptionLabel={(option) => option.label ||  "test"}
                      onChange={(e, value) => {
                        if (value !== null) {
                          setFieldValue("vender", value);
                        } else {
                          setFieldValue("vender", "");
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          className={classes.inputMargin}
                          {...params}
                          fullWidth={true}
                          label="Select Vendor*"
                          variant="outlined"
                          name="vender"
                          error={Boolean(touched.vender && errors.vender)}
                          helperText={touched.vender && errors.vender}
                          autoComplete="off"
                        />
                      )}
                    />
                    <ErrorFocus />
                  </Grid> :
                  <div></div>}

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    className={classes.popDownBtn}
                    size="medium"
                    type="submit"
                    variant="contained"
                  >
                    {loading === false ? <> {props.mode === "edit" ? "UPDATE" : "ADD"}</> : <><img alt="loader" src={loadingSpinner} className={classes.buttonProgress} /> {props.mode === "edit" ? "UPDATE" : "ADD"} </>}

                  </Button>
                </Grid>
              </Grid>
            </Container>
          </form>
        )}
      </Formik>
      <SessionExpiredModal
        open={openSessionExpiredModal}

      />
    </StyledContainer>
  );
}

const mapStateToProps = state => {
  return ({
    force: state, // force state from reducer
    authReducer: state.authReducer,
  })
};

export default connect(mapStateToProps, null)(AdditionalTradeCreateForm);

