/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  Button,
} from "@mui/material";

import Tooltip from "@mui/material/Tooltip";
import ModalCentered from "src/components/Modals/ModalCentered";
import SelectUserAccountForm from "src/containers/Users/SelectUserAccount/SelectUserAccountForm";
import SnackBar from "src/components/SnackBar/SnackBar";
import NavItem from "./NavItem";
import { connect } from "react-redux";
import { logoutSuccess, switchRole } from "src/store/bugs";
import { loginService } from "src/_services/login";
import Cookies from "universal-cookie";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";

const PREFIX = 'index';

const classes = {
  mobileDrawer: `${PREFIX}-mobileDrawer`,
  desktopDrawer: `${PREFIX}-desktopDrawer`,
  avatar: `${PREFIX}-avatar`,
  userBg: `${PREFIX}-userBg`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.mobileDrawer}`]: {
    width: 256,
  },

  [`& .${classes.desktopDrawer}`]: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },

  [`& .${classes.avatar}`]: {
    cursor: "pointer",
    width: 64,
    height: 64,
    fontSize: "36px",
  },

  [`& .${classes.userBg}`]: {
    backgroundImage: `url('/static/images/user-bg-1.png')`,
    backgroundPosition: `right bottom`,
    backgroundRepeat: "no-repeat",
  }
}));

const cookies = new Cookies();
const user = {
  avatar: "/static/images/avatars/icon-user-default.png",
  jobTitle: "Admin",
  name: "Jyotheesh",
};

const items = [
  {
    href: "/app/dashboard",
    icon: "dashboard_outlined",
    title: "Dashboard",
    message: "Dashboard",
  },
  // {
  //   href: "/app/analytics",
  //   icon: "trending_up",
  //   title: "Analytics",
  //   message: "Dashboard Analytics",
  // },
  {
    href: "/app/security",
    icon: "monetization_on_outlined",
    title: "Scrips",
    message: "Master List of Scrips, Control of Inventory & Price",
  },
  {
    href: "/app/user",
    icon: "people",
    title: "Users",
    message: "Master List of Users and Their Role Control",
  },
  // {
  //   href: "/app/procure-shares",
  //   icon: "monetization_on_outlined",
  //   title: "Procurements",
  //   message: "List of Investor Request",
  // },
  // {
  //   href: "/app/payment-details",
  //   icon: "payment",
  //   title: "Online Procurement",
  //   message: "List of Transactions",
  // },
  // {
  //   href: "/app/escrow-orders",
  //   icon: "monetization_on_outlined",
  //   title: "Escrow Order Details",
  //   message: "List of Transactions",
  // },
  {
    href: "/app/service-order-lists",
    icon: "confirmation_number_outlined",
    title: "Orders",
    message: "List of Trade",
  },
  {
    href: "/app/addition-trade",
    icon: "more",
    title: "Additional Trade",
    message: "Potential Scrips Available/Request",
  },
  {
    href: "/app/secondary-trade",
    icon: "local_offer_outlined",
    title: "Secondary Trade",
    message: "List of Secondary Available",
  },
  {
    href: "/app/partners",
    icon: "diversity_3",
    title: "Partners",
    message: "List of Partners",
    subMenu:[{
      href: "/app/partners",
      icon: "people",
      title: "Users",
      message: "List of Users",
    },
    {
      href: "/app/partner_orders",
      icon: "confirmation_number_outlined",
      title: "Orders",
      message: "List of Orders",
    }]
  },
  {
    href: "/app/logs",
    icon: "track_changes",
    title: "Logs",
    message: "List of dashboard changes",
  },
  {
    href: "/app/FAQs",
    icon: "help_outline",
    title: "FAQ's",
    message: "List of FAQs",
  },
  // {
  //   href: "/app/tickets",
  //   icon: "support",
  //   title: "Tickets",
  //   message: "Support Tickets",
  // },
  {
    href: "/app/policy",
    icon: "business-time",
    title: "Policy",
    message: "List of Policy",
  },
  // {
  //   href: '/app/knowledgeBase',
  //   icon: 'business-time',
  //   title: 'Knowledge Base',
  //   message: "knowledge base"
  // },

  // {
  //   href: '/app/reports',
  //   icon: 'bar_chart',
  //   title: 'Reports'
  // },
  // {
  //   href: '/login',
  //   icon: 'LockIcon',
  //   title: 'Login'
  // },
  // {
  //   href: '/register',
  //   icon: 'UserPlusIcon',
  //   title: 'Register'
  // },
  // {
  //   href: '/404',
  //   icon: 'AlertCircleIcon',
  //   title: 'Error'
  // }
];

const itemSales = [
  {
    href: "/app/dashboard",
    icon: "dashboard_outlined",
    title: "Dashboard",
    message: "Dashboard",
  },
  // {
  //   href: "/app/service-order-lists",
  //   icon: "confirmation_number_outlined",
  //   title: "Service Orders",
  //   message: "List of Trade",
  // },
  {
    href: "/app/security",
    icon: "monetization_on_outlined",
    title: "Scrips",
    message: "Master List of Scrips",
  },
  {
    href: "/app/user",
    icon: "people",
    title: "Users",
    message: "Master List of Users and Their Role Control",
  },
  {
    href: "/app/service-order-lists",
    icon: "confirmation_number_outlined",
    title: "Orders",
    message: "List of Trade",
  },
  {
    href: "/app/channel-partners",
    icon: "people_outlined",
    title: "External RM's",
    message: "List of External Channel Partner",
  },
  {
    href: "/app/group-create",
    icon: "people_outlined",
    title: "ERM's Commission Groups",
    message: "Controlled Commission Displayed Channel Partner",
  },
  {
    href: "/app/irm/inventory-and-price",
    icon: "list_alt_outlined",
    title: "Inventory & Price",
    message: "Control of Inventory & Price",
  },

  {
    href: "/app/addition-trade",
    icon: "more_outlined",
    title: "Additional Trade",
    message: "Potential Scrips Available/Request",
  },
  {
    href: "/app/secondary-trade",
    icon: "local_offer_outlined",
    title: "Secondary Trade",
    message: "List of Secondary Available",
  },
  {
    href: "/app/policy",
    icon: "business-time",
    title: "Policy",
    message: "List of Policy",
  },
];

const menusChannelPartners = [
  {
    href: "/app/channel-partners-dashboard",
    icon: "dashboard_outlined",
    title: "Dashboard",
    message: "Dashboard",
  },
  {
    href: "channel-partners-views",
    icon: "list_alt_outlined",
    title: "Scrips & Inventories",
    message: "Master List of Scrips",
  },
  {
    href: "/app/channel-partners-service-order-lists",
    icon: "confirmation_number_outlined",
    title: "Orders",
    message: "List of Trade",
  },
  {
    href: "/app/policy",
    icon: "business-time",
    title: "Policy",
    message: "List of Policy",
  },
];

const menuClients = [
  {
    href: "/app/investor-dashboard",
    icon: "dashboard_outlined",
    title: "Console",
    message: "Dashboard",
  },
  {
    href: "/app/investor-client-procure-share",
    icon: "monetization_on_outlined",
    title: "Buy Shares",
    message: "List of Scrip Available",
  },
  {
    href: "/app/investor-client-service-order-lists",
    icon: "confirmation_number_outlined",
    title: "Transaction",
    message: "List of Trade",
  },
  {
    href: "/app/policy",
    icon: "business-time",
    title: "Policy",
    message: "List of Policy",
  },
];

const NavBar = ({
  onMobileClose,
  openMobile,
  authReducer,
  switchRole,
  fetchArticleDetails,
}) => {

  const location = useLocation();
  const [roleDescription, setRoleDescription] = useState(
    authReducer.auth
      ? authReducer.auth.user !== null &&
          authReducer.auth.user.default_description
      : null
  );
  const [role, setRole] = useState(
    authReducer.auth
      ? authReducer.auth.user !== null &&
          authReducer.auth.user.default_role_code
      : null
  );
  const [userMenu, setUserMenu] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState("");
  const [snackbarTitle, setSnackbarTitle] = React.useState("");
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (role === "SALES") {
      setUserMenu(itemSales);
    } else if (role === "OPERATION") {
      setUserMenu(items);
    } else if (role === "ADMIN") {
      setUserMenu(items);
    } else if (role === "ADMIN_VIEWER") {
      setUserMenu(items);
    } else if (role === "INVESTER_CLIENT") {
      setUserMenu(menuClients);
    } else if (role === "CHANNEL_PARTNER") {
      setUserMenu(menusChannelPartners);
    } else {
    }
  }, [role]);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [show, setShow] = useState(false);

  const openShow = () => {
    if (authReducer.auth.user.user_role.length > 1) {
      setShow(true);
    }
  };

  const closeShow = () => {
    setShow(false);
  };

  const submitHandle = (values) => {
    setRole(values.role);
    setRoleDescription(values.description);

    if (values.setDefault) {
      loginService.setDefaultRole(values.role).then((res) => {
        if (res) {
        }
      });
    }

    loginService.switchRole(values.role).then((res) => {
      if (res) {
        if (res.status === 200) {
          cookies.set("token", res.data.Jwttoken.token, {
            path: "/",
            expires: new Date(new Date().getTime() + 60 * 60 * 1000 * 24 * 180),
          });

          setShow(false);
          setSeverity("success");
          setSnackbarTitle("Account Switched");
          switchRole({ role: values.role, description: values.description });

          setOpen(true);
          if (values.role === "ADMIN" || values.role === "ADMIN_VIEWER") {
            navigate("/app/dashboard", { replace: true });
          } else if (values.role === "SALES") {
            navigate("/app/dashboard", { replace: true });
          } else if (values.role === "OPERATION") {
            navigate("/app/dashboard", { replace: true });
          } else if (values.role === "CHANNEL_PARTNER") {
            navigate("/app/investor-dashboard", { replace: true });
          } else if (values.role === "INVESTER_CLIENT") {
            navigate("/app/investor-dashboard", { replace: true });
          } else {
          }
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true);
          }
        }
      }
    });
  };

  useEffect(() => {
    // console.log(authReducer)
    // console.log('middleware check', fetchArticleDetails)
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Root>
    <Box height="100%" display="flex" flexDirection="column">
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={1}
        className={classes.userBg}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          alt={authReducer.auth? authReducer.auth.user !== null && authReducer.auth.user.display_name: null}
          src={user.avatar}
          to="/app/profile"
        />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {authReducer.auth? authReducer.auth.user !== null && authReducer.auth.user.display_name: null}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          <Button onClick={openShow} color="primary">
            {roleDescription}
          </Button>
        </Typography>
      </Box>
      <Divider />
      <Box p={0}>
        <List>
          {userMenu &&
            userMenu.map((item) => (
              <Tooltip title={item.message} key={item.href} arrow placement="right">
                <div>
                  <NavItem
                    href={item.href}
                    key={item.title}
                    title={item.title}
                    icon={item.icon}
                    subMenu={item.subMenu}
                  />
                </div>
              </Tooltip>
            ))}
        </List>
      </Box>
    </Box>
    </Root>
  );


        
  return (
    (<Root>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          sx={{
            "& .MuiPaper-root":{
                width:255
            }
          }}
          // classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
      <ModalCentered
        title="Select Account"
        open={show}
        toggle={closeShow}
        children={
          <SelectUserAccountForm
            data={
              authReducer.auth
                ? authReducer.auth.user !== null &&
                  authReducer.auth.user.user_role
                : null
            }
            formSubmit={submitHandle}
            defaultRole={role}
          />
        }
      />
      <SnackBar
        open={open}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />
      <SessionExpiredModal open={openSessionExpiredModal} />
    </Root>)
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};
const dispatchToProps = (dispatch) => ({
  logoutSuccess: () => dispatch(logoutSuccess()),
  switchRole: (role) => dispatch(switchRole(role)),
});

export default connect(mapStateToProps, dispatchToProps)(NavBar);
