import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import * as Yup from "yup";
import { Formik } from "formik";
import Page from "src/components/Page";
import {
  Box,
  Button,
  Container,
  Card,
  Grid,
  Typography,
} from "@mui/material";

import { loginService } from "src/_services/login";
import SnackBar from "src/components/SnackBar/SnackBar";
import FormHelperText from '@mui/material/FormHelperText';
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./style.css";
// import { auth } from './firebase';
import { RecaptchaVerifier, signInWithPhoneNumber ,getAuth} from 'firebase/auth';
import { initializeApp,getApps } from "firebase/app";
import DisableRightClick from "src/_helpers/DisableRightClick";
const PREFIX = 'LoginPage';

const classes = {
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  imageSizes: `${PREFIX}-imageSizes`,
  loginLink: `${PREFIX}-loginLink`,
  container: `${PREFIX}-container`,
  linkNbtn: `${PREFIX}-linkNbtn`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  pageLayout: `${PREFIX}-pageLayout`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.loginLink}`]: {
    color: "#3f51b5,",
    fontSize: "13px",
    border: " 1px solid #3f51b5",
    padding: "4px 16px",
    fontWeight: "500",
    height: "29px",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    justifyItems: "center",
    marginTop: "10%",
  },

  [`& .${classes.linkNbtn}`]: {
    display: "flex",
    justifyContent: "space-between",
    margin: "-4px 0 40px 0px",
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.pageLayout}`]: {
    height: "calc(100vh - 128px)",
    padding: "0px !important",
  }
}));


const LoginPage = () => {
  let auth;

  const navigate = useNavigate();
  const [submit,setSubmit] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [severity, setSeverity] = React.useState("");
  const [valueColor, setValueColor] = useState(false)
  const phoneRegex = RegExp(/^[0-9]{12}$/);
  const state ={
    submitted: false,
    title: "Login",
    buttonText: "Continue",
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
 
async function initializeFirebae(){
  try {
    let app;
    let firebaseEnv = process.env.REACT_APP_ADMIN_CLIENT.replace(/\\/g, '');
    let firebaseEnvFormated = firebaseEnv.replace(/""/g, '');
    let firebaseEnvFormatedObject = JSON.parse(firebaseEnvFormated)
      if(getApps().length === 0 ){
      app = initializeApp(firebaseEnvFormatedObject.REACT_APP_ADMIN_CLIENT);
    }
    auth = getAuth(app);
  } catch (error) {
   console.log(error);
  } 
 }

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha',{
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      },
      'expired-callback': () => {
        let appVerifier = window.recaptchaVerifier;
        appVerifier.render()
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
    }
    }, auth);
  }

  const handleOnSubmit =async (values) => {
    await initializeFirebae()
    loginService
      .mobileAuthentication(`+${values.mobileNumber}`)
      .then(async(res) => {
        if (res) {
          setSubmit(false)
          if (res.status === 200) {
            generateRecaptcha();
            let appVerifier = window.recaptchaVerifier;
            signInWithPhoneNumber(auth, `+${values.mobileNumber}`, appVerifier)
              .then((confirmationResult) => {
                 window.confirmationResult = confirmationResult;
                 navigate("/verify-otp", {
                   replace: true,
                   state: { mobile: `+${values.mobileNumber}`,auth:JSON.stringify(auth) },
                   type: "login",
                 });
              }).catch((error) => {
                // Error; SMS not sent
                console.log(error);
              });
          } else if (res.status === 401) {
            setSubmit(false)
              if (res.data === "REQUIRED_REGISTRATION") {
              setSeverity("error");
              setSnackbarTitle("Please enter registered mobile no");
              setOpen(true);
              } else {
                if(res.data.message==="ACCOUNT_BLOCKED"){
                  setSeverity("error")
                  setSnackbarTitle("Your account is blocked")
                  setOpen(true);
                  
                }
              }
          } else if (res.status === 400) {
            setSubmit(false)
            setSeverity("error");
            setSnackbarTitle("ACCOUNT HAS BEEN SUSPENDED");
            setOpen(true);
          } else if (res.status === 204) {
            setSeverity("error");
            setSnackbarTitle("Mobile no is required");
            setOpen(true);
          } else if (res.status === 403) {
            setSeverity("error");
            setSnackbarTitle("You Don't have Access to Login");
            setOpen(true);
          }
        }
      })
      .catch((error) => 
      {
        setSubmit(false)
        console.log(error)
    });
  };

  const handleError = (value) => {
    if (value && value.length <= 2) {
        setValueColor(true)
    }
    else if (value.length <= 11) {
        setValueColor(true)
    }
    else {
        setValueColor(false)
    }
  }

  return (
    <Root>
    <Page addClass={classes.pageLayout} title="Unlistedkart | Login">
      <DisableRightClick>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        sx={{backgroundColor:'#f4f6f8'}}
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            enableReinitialize={true}
            initialValues={{mobileNumber: "",}}
            validationSchema={Yup.object().shape({
              mobileNumber: Yup.string()
                .nullable()
                .matches(phoneRegex, "Enter a valid Mobile Number")
                .required("Mobile Number is required"),
            })}
            onSubmit={(values,) =>{ 
              handleOnSubmit(values)
              setSubmit(true)
            }}
          >
            {({
              errors,
              isSubmitting,
              handleSubmit,
              setFieldValue,
              handleBlur,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container direction="column">
                  <Card className={classes.cardBorderRadius}>
                    <Typography
                      className={classes.cardHeaderMargin}
                      variant="h5"
                    >
                      {state.title}
                    </Typography>

                    <Container className={classes.container}>
                      <Typography
                        color="primary"
                        gutterBottom
                        variant="h5"
                        className={classes.marginInputLabel}
                      >
                        Enter your registered mobile number
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <PhoneInput
                            id="mobileNumber"
                            name="mobileNumber"
                            countryCodeEditable={false}
                            // onlyCountries={["in","us","au","sg","hk","ae","gb","nz","ph"]}
                            style={(valueColor ? { border: "1px solid red", borderRadius: "5px" } : { border: "1px solid #314B8F", borderRadius: "5px" })}
                            country={"in"}
                            placeholder="Enter phone number"
                            value={values.mobileNumber}
                            handleBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue("mobileNumber", e)
                              
                            }}
                          />
                          <FormHelperText style={{ color: "red" }}>{(errors.mobileNumber && touched.mobileNumber) && errors.mobileNumber}</FormHelperText>  
                        </Grid>
                      </Grid>
                      <br />
                      <div className={classes.linkNbtn}>
                      {/* <Typography variant='body2' > Don’t have an account yet? &nbsp;
                      <Typography variant='body2' >
                         {/* Don’t have an account yet? &nbsp;
                            <Typography
                              variant='body2' 
                              component={LinkBtn}
                              to={`/register`}
                              color="primary"
                            >
                              REGISTER.
                            </Typography>
                          </Typography> */}
                        <Button
                          color="primary"
                          disabled={submit}   
                          size="medium"
                          type="submit"
                          variant="contained"
                          onClick={() => handleError(values.mobileNumber)}
                        >
                          {state.buttonText}
                        </Button>
                      </div>
                    </Container>
                  </Card>
                </Grid>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
      <SnackBar
        open={open}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />
      <div id="recaptcha"></div>
      </DisableRightClick>
    </Page>
    </Root>
  );
};

export default LoginPage;

