import React, {  useState } from 'react'
import { Formik } from "formik";
import { Grid, Typography, Container, Button, TextField, Select, MenuItem } from '@mui/material';
import { styled } from "@mui/material/styles";
import loadingSpinner from "src/images/loading-spinner.gif";
const PREFIX = "AdvanceSearchForm";

const classes = {
    container: `${PREFIX}-container`,
    label: `${PREFIX}-label`,
    popDownBtn: `${PREFIX}-popDownBtn`,
    buttonProgress: `${PREFIX}-buttonProgress`,
};


const StyledContainer = styled(Container)(({ theme }) => ({
    [`& .${classes.container}`]: {
        justifyContent: "center",
        justifyItems: "center",

        marginTop: "5%",
    },

    [`& .${classes.label}`]: {
        marginTop: "10px",
        fontWeight: "bold",
    },

    [`& .${classes.popDownBtn}`]: {
        borderRadius: "0 0 12px 12px",
        padding: "8px",
        float: "right",

        marginTop: theme.spacing(6),
        marginBottom: "10%",
    },

    [`& .${classes.buttonProgress}`]: {
        marginRight: "4px",
    },
}));
const UpdateTicket = ({ data ,formSubmit}) => {
    const [loading, setLoading] = useState(false)
    const initailValues = {
        id: data[0],
        status: data[5],
        description: data[4],
        question: data[3],
        feedback: data[8],
        fileId:data[7],
        url: data[7]
    }
    // const handleFileUrl = () => {
    //     setLoading(true)
    //     getLookUpDataService.getObjectUrl(data[7]).then((res) => {
    //         if (res.data.success) {
    //     setLoading(false)
    //             setInitialValues({
    //                 ...initailValues,
    //                 // url:res.data.data
    //                 url: 'https://i0.wp.com/digital-photography-school.com/wp-content/uploads/2021/04/zoom-effect-1.jpg?fit=1500%2C1001&ssl=1'
    //             });

    //         }
    //     });
    // };

    // useEffect(() => {
    //     if (data && data[7]) {
    //         handleFileUrl()
    //     }
    // }, [])


    const renderDetails = ((label, value) => {
        return (
            <>
                <Grid item xs={2} mt={1}>
                    <Typography sx={{ fontWeight: 'bold' }}>

                        {label} :
                    </Typography>
                </Grid>
                <Grid item xs={10} mt={1}>
                    <Typography sx={{ marginLeft: '8px' }}>
                        {value}
                    </Typography>
                </Grid></>
        )
    })
    return (
        <StyledContainer>
            <Formik
                enableReinitialize={true}
                initialValues={initailValues}
                // validationSchema={Yup.object().shape({
                   
                //   })}
                  onSubmit={(values) => {
                    setLoading(true)
                    formSubmit(values)
                      
                  }}
            >

                {({ handleSubmit, handleChange, touched, setFieldValue, handleBlur, errors, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Container className={classes.container}>

                            {data.length > 0 ?
                                <>
                                    {/* <Typography variant="h4">
                                        Ticket Details
                                    </Typography> */}
                                    <Grid container rowSpacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container sx={{ fontWeight: "500", mt: 2 }}>
                                                {renderDetails('Question', values.question)}
                                                {renderDetails('Message', values.description)}
                                                {values.fileId ?
                                                    <>
                                                        <Grid item xs={2}>
                                                            <Typography sx={{ fontWeight: 'bold', padding: '16px 0' }}>
                                                                File :
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={10} sx={{ padding:values.url ? '16px' : '16px 8px' }}>
                                                           {values.url ?  <img style={{
                                                                width: '500px',
                                                                objectFit: 'contain',
                                                            }}  loading="lazy" src={values.url} alt="" /> : "Loading..."}
                                                        </Grid>
                                                    </>
                                                    : null}
                                                <Grid item xs={2} mt={1}>
                                                    <Typography sx={{ fontWeight: 'bold' }}>
                                                        FeedBack :
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={10} mt={1}>
                                                    <TextField
                                                        placeholder="feedback"
                                                        name="feedback"
                                                        fullWidth
                                                        value={values.feedback}
                                                        onChange={handleChange}
                                                        multiline
                                                        rows={2}
                                                    />
                                                </Grid>
                                                <Grid item xs={2} pt={2}>
                                                    <Typography sx={{ fontWeight: 'bold' }}>
                                                        Status :
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={10} pt={2}>
                                                <Select
                                                    id="status"
                                                    fullWidth
                                                    style={{ marginBottom: 8 }}
                                                    variant="outlined"
                                                    error={Boolean(touched.status && errors.status)}
                                                    name="status"
                                                    value={values.status}
                                                    placeholder="Account Type"
                                                    aria-describedby="outlined-weight-helper-text"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    autoComplete="off"
                                                >
                                                    <MenuItem key="saving" value="1">
                                                        Open
                                                    </MenuItem>
                                                    <MenuItem value="2">
                                                        In Progress
                                                    </MenuItem>
                                                    <MenuItem key="current" value="3">
                                                        Closed
                                                    </MenuItem>
                                                </Select>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Button
                                        color="primary"
                                        className={classes.popDownBtn}
                                        size="medium"
                                        disabled={loading}
                                        type="submit"
                                        variant="contained"
                                    >
                                        {loading === false ? (
                                            "Update"
                                        ) : (
                                            <>
                                                <img
                                                    src={loadingSpinner}
                                                    alt="Logo"
                                                    className={classes.buttonProgress}
                                                />{" "}
                                                Update{" "}
                                            </>
                                        )}
                                    </Button>
                                </> :
                                <Typography sx={{ textAlign: 'center', my: 3 }}>No accounts found</Typography>}
                        </Container>
                    </form>
                )}
            </Formik>
        </StyledContainer>
    )
}

export default UpdateTicket