import React, { useState,useEffect } from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";
import {
  FormControl,
  RadioGroup,
  Radio,
  FormLabel,
  List,
  ListItem,
  ListItemText,
  FormHelperText,
  Container,
  TextField,
  Grid,
  Typography,
  FormControlLabel,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
// import {additionalTradeService} from 'src/_services/additionalTrade';

import loadingSpinner from 'src/images/loading-spinner.gif'

const PREFIX = 'AddSecondoryCreateFormAndDetails';

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  fundName: `${PREFIX}-fundName`,
  commitAmount: `${PREFIX}-commitAmount`,
  rapidCapital: `${PREFIX}-rapidCapital`,
  price: `${PREFIX}-price`,
  remarks: `${PREFIX}-remarks`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  paddingInput: `${PREFIX}-paddingInput`,
  buttonProgress: `${PREFIX}-buttonProgress`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.fundName}`]: {
    [theme.breakpoints.down("xs")]: {
      minWidth: 82,
    },
  },

  [`& .${classes.commitAmount}`]: {
    [theme.breakpoints.down("xs")]: {
      minWidth: 145,
    },
  },

  [`& .${classes.rapidCapital}`]: {
    [theme.breakpoints.down("xs")]: {
      minWidth: 112,
    },
  },

  [`& .${classes.price}`]: {
    [theme.breakpoints.down("xs")]: {
      minWidth: 40,
    },
  },

  [`& .${classes.remarks}`]: {
    paddingTop: 5,
    paddingRight: 1,
    [theme.breakpoints.down("xs")]: {
      minWidth: 67,
    },
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.paddingInput}`]: {
    padding: "0px 0px",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: '4px'
  }
}));

function AddSecondoryCreateFormAndDetails(props) {

  const [formInitValues ,setFormInitValues] = useState({
    fund_name: "",
    transfer_type: "",
    commitment_amount: "",
    resipual_capital:"",
    price: "",
    remarks: "",
  })
  
  const [loading , setLoading] = React.useState(false)

  useEffect(() => {
    if(props.mode==="edit"){
      let formEditValues = {...formInitValues}
      formEditValues.fund_name=props.data[1]
      formEditValues.transfer_type=props.data[2]
      formEditValues.commitment_amount=props.data[3]
      formEditValues.resipual_capital=props.data[4]
      formEditValues.price=props.data[6]
      formEditValues.remarks=props.data[8]
      setFormInitValues(formEditValues)
    }
  },[])

  const transferTypes = [
    { id: 1, label: "SELL" },
    { id: 2, label: "BUY" },
  ];

  // const  handleOnSubmitValue =(values) =>{
   
  // }

  return (
    <StyledContainer style={props.type !== "Add" ? { padding: 0 } : {}}>
      {props.type === "Add" ? (
        <Formik
          enableReinitialize={true}
          initialValues={formInitValues}
          validationSchema={Yup.object().shape({
            fund_name: Yup.string().required("Required"),
            transfer_type: Yup.string().required("Required"),
            commitment_amount:  Yup.number().positive().nullable().required("Required"),
            price:Yup.number().positive().nullable().required("Required"),
            resipual_capital: Yup.string().required("Required"),
          })}
          onSubmit={(values) => {
            values.price = parseFloat(Number(values.price).toFixed(2))
            values.commitment_amount = parseFloat(Number(values.commitment_amount).toFixed(2))
            setLoading(true)
            props.formSubmit(values) 
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            isSubmitting,
            setFieldValue,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <div></div>
              <Container className={classes.container}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl component="fieldset" color="primary">
                      <FormLabel component="legend" color="primary">
                        Transfer Type *
                      </FormLabel>
                      <RadioGroup
                        aria-label="transfer_type"
                        name="transfer_type"
                        color="primary"
                        value={values.transfer_type}
                        // error={Boolean(
                        //   touched.serviceType && errors.serviceType
                        // )}
                        // helperText={
                        //   touched.transfer_type && errors.transfer_type
                        // }
                        onChange={handleChange}
                        // inputProps={{ autoFocus: true }}
                      >
                        <div>
                          {transferTypes.map((item, i) => {
                            return (
                              <FormControlLabel
                               key={i}
                                value={item.label}
                                color="primary"
                                control={<Radio color="primary" />}
                                label={item.label}
                              />
                            );
                          })}
                        </div>
                      </RadioGroup>
                      <FormHelperText
                        style={{
                          color: "#F44336",
                          marginBottom: 16,
                          marginTop: "-6px",
                        }}
                      >
                        {errors.transfer_type &&
                          touched.transfer_type &&
                          errors.transfer_type}
                      </FormHelperText>
                    </FormControl>
                    <ErrorFocus />
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        component={'span'}
                        variant="outlined"
                        label="Fund Name *"
                        id="fund_name"
                        type="text"
                        name="fund_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                        value={values.fund_name}
                        error={Boolean(touched.fund_name && errors.fund_name)}
                        helperText={touched.fund_name && errors.fund_name}
                        aria-describedby="outlined-weight-helper-text"
                        autoComplete="off"
                        // onInput={(e) => { e.target.value = e.target.value.replace(/^\d*\.?\d*$/g, "") }}
                        // onKeyPress={(e)=>{e.target.value = e.target.value.replace(/^\d+\.\d{0,2}$/,"")}}
                      />
                      <ErrorFocus />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        component={'span'}
                        className={classes.inputMargin}
                        variant="outlined"
                        label="Residual Capital *"
                        id="resipual_capital"
                        name="resipual_capital"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                        value={values.resipual_capital}
                        error={Boolean(
                          touched.resipual_capital && errors.resipual_capital
                        )}
                        helperText={
                          touched.resipual_capital && errors.resipual_capital
                        }
                        aria-describedby="outlined-weight-helper-text"
                        autoComplete="off"
                      />
                      <ErrorFocus />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        component={'span'}
                        className={classes.inputMargin}
                        variant="outlined"
                        label="Price*"
                        id="price"
                        type="number"
                        name="price"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                        value={values.price}
                        error={Boolean(touched.price && errors.price)}
                        helperText={touched.price && errors.price}
                        aria-describedby="outlined-weight-helper-text"
                        autoComplete="off"
                        // onInput={(e) => { e.target.value = e.target.value.replace(/^\d*\.?\d*$/g, "") }}
                        // onKeyPress={(e)=>{e.target.value = e.target.value.replace(/^\d+\.\d{0,2}$/,"")}}
                      />

                      <ErrorFocus />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        component={'span'}
                        className={classes.inputMargin}
                        variant="outlined"
                        label="Commitment Amount *"
                        id="commitment_amount"
                        type="number"
                        name="commitment_amount"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                        value={values.commitment_amount}
                        error={Boolean(
                          touched.commitment_amount && errors.commitment_amount
                        )}
                        helperText={
                          touched.commitment_amount && errors.commitment_amount
                        }
                        aria-describedby="outlined-weight-helper-text"
                        autoComplete="off"
                        // onInput={(e) => remarks{ e.target.value = e.target.value.replace(/^\d*\.?\d*$/g, "") }}
                        // onKeyPress={(e)=>{e.target.value = e.target.value.replace(/^\d+\.\d{0,2}$/,"")}}
                      />
                      <ErrorFocus />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        component={'span'}
                        id="remarks"
                        label="Remarks"
                        error={Boolean(touched.remarks && errors.remarks)}
                        helperText={touched.remarks && errors.remarks}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="remarks"
                        value={values.remarks}
                        multiline
                        variant="outlined"
                        minRows={2}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  className={classes.popDownBtn}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  {loading === false ?<> {props.mode==="edit" ? "UPDATE" : "ADD"}</>: <><img src={loadingSpinner} alt="logo" className={classes.buttonProgress}/> {props.mode==="edit" ? "UPDATE" : "ADD"} </>}
                  
                </Button>
                </Grid>
                </Grid>
              </Container>
            </form>
          )}
        </Formik>
      ) : (
        <div style={{ textAlign: "Left", paddingTop: 32, paddingBottom: 32 }}>
            <List component="nav" aria-label="main mailbox folders">
            <ListItem style={{ display: "webkit-box", alignItems: "baseline" }}>
              <Typography variant="subtitle2" className={classes.fundName}>
                Fund Name :{" "}
              </Typography>
              <ListItemText
                primary={props.type[1]}
                style={{textTransform: 'capitalize',paddingLeft: 4}}
              />
            </ListItem>
            <ListItem>
              <Typography variant="subtitle2">Transfer Type : </Typography>
              <ListItemText
                 primary={props.type[2]}
                style={{ paddingLeft: 4 }}
              />
            </ListItem>
            <ListItem style={{ display: "webkit-box", alignItems: "baseline" }}>
              <Typography variant="subtitle2" className={classes.commitAmount}>
                Commitment Amount :{" "}
              </Typography>
              <ListItemText
                primary={props.type[3]}
                style={{ paddingLeft: 4 }}
              />
            </ListItem>
            <ListItem style={{ display: "webkit-box", alignItems: "baseline" }}>
              <Typography variant="subtitle2" className={classes.rapidCapital}>
                Residual Capital :{" "}
              </Typography>
              <ListItemText
                 primary={props.type[4]}
                style={{ paddingLeft: 4 }}
              />
            </ListItem>
            <ListItem>
              <Typography variant="subtitle2" className={classes.price}>
                Price :{" "}
              </Typography>
              <ListItemText
                primary={props.type[6]}
                style={{ paddingLeft: 4 }}
              />
            </ListItem>
            <ListItem style={{ display: "webkit-box", alignItems: "baseline" }}>
              <Typography variant="subtitle2" className={classes.remarks}>
                Remarks :{" "}
              </Typography>
              <ListItemText
                 primary={props.type[8]}
                style={{ paddingLeft: 2 }}
              />
            </ListItem>
          </List>
        </div>
      )}
    </StyledContainer>
  );
}

export default AddSecondoryCreateFormAndDetails;
