import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import {
  Container,
  Grid,
  Checkbox,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import loadingSpinner from "src/images/loading-spinner.gif";
// import Typography from "src/theme/typography";
import { userService } from "src/_services/users";

const PREFIX = "AdvanceSearchForm";

const classes = {
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  buttonProgress: `${PREFIX}-buttonProgress`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.container}`]: {
    justifyContent: "center",
    justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginTop: "10px",
    fontWeight: "bold",
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",

    marginTop: theme.spacing(6),
    marginBottom: "10%",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  },
}));

function AdvanceSearchForm({ state, formSubmit,role }) {
  const [data, setData] = useState([]);

  useEffect(() => {
      getDematAccounts();
  }, []);

  const getDematAccounts = () => {
    setLoading(true)
    userService.getUserDemat(state.id).then((res) => {
      if (res) {
        if (res.status === 200) {
         setLoading(false)
          setData(res.data);
        } 
      }
    });
  };

  const [loading, setLoading] = React.useState(false);

  const dematId = data.map((item)=>{
    return(
      {status:item.is_beneficiary_mapped_demat,
      id:item.id}
    )
  })

  return (
    <StyledContainer>
      <Formik
        initialValues={{
          id: state.id,
          status: state.status,
          dematId:dematId
        }}
      >
        {({ handleSubmit, handleChange, setFieldValue, values }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>

             {data.length>0 ? 
             <>
              <Typography variant="h6" mt={3}>
                Demat Accounts
              </Typography>
             <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  <Grid container sx={{ fontWeight: "500", mt: 2 }}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={4}>
                      DP With
                    </Grid>
                    <Grid item xs={3}>
                      DP ID
                    </Grid>
                    <Grid item xs={3}>
                      Client ID
                    </Grid>
                  </Grid>

                  {!loading ? data.map((item,index) => {
                    return (
                      <Grid container sx={{ alignItems: "center" }}>
                        <Grid item xs={2}>
                          <Checkbox 
                          disabled={role !== "ADMIN" && role !== "OPERATION"}                     
                            checked={item.is_beneficiary_mapped_demat}
                            onChange={()=>{
                              setData(prevState => {
                                return prevState.map((item,newIndex) => {
                                  if (newIndex === index) {
                                    return { ...item, is_beneficiary_mapped_demat: !item.is_beneficiary_mapped_demat };
                                  }
                                  return item;
                                });
                              });
                            }}
                            name="dpId"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          {item.dp_with}
                        </Grid>
                        <Grid item xs={3}>
                          {item.dp_id}
                        </Grid>
                        <Grid item xs={3}>
                          {item.client_id}
                        </Grid>
                      </Grid>
                    );
                  }): <Typography sx={{textAlign:'center', m:2}}>Loading...</Typography>}
                </Grid>
              </Grid>
              <Button
                color="primary"
                disabled={loading || (role !== "ADMIN" && role !== "OPERATION")}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                onClick={() => {
                  setLoading(true);
                  formSubmit(values,data);
                }}
                variant="contained"
              >
                {loading === false ? (
                  "Update"
                ) : (
                  <>
                    <img
                      src={loadingSpinner}
                      alt="Logo"
                      className={classes.buttonProgress}
                    />{" "}
                    Update{" "}
                  </>
                )}
              </Button>
             </> : 
              <Typography sx={{textAlign:'center', my:3}}>No accounts found</Typography>}
            </Container>
          </form>
        )}
      </Formik>
    </StyledContainer>
  );
}

export default AdvanceSearchForm;
