import React, { forwardRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import BackToTop from "../components/BackToTop/BackToTop"
import clsx from 'clsx'
import { connect } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";



const PREFIX = 'Page';

const classes = {
  root: `${PREFIX}-root`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {        
    // backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3), 
    padding: theme.spacing(3),   
    [theme.breakpoints.down("xs")]: {
      padding: '0px 10px'
    },  
     
  }
}));


const Page = forwardRef(({
  children,
  title = '',
  isAuthenticated,
  addClass=null,
  ...rest
}, ref) => {

  const navigate = useNavigate();
  let location = useLocation();


  useEffect(()=>{
    let allow = false;
    const userData = rest.force.authReducer.auth?.user
    if(location.pathname==="/login" || location.pathname==="/verify-otp" || location.pathname==="/register" || location.pathname==="/system/version"){
      allow=true
    }
    else{
      allow=false;
    }

    if(!allow){
      if(!isAuthenticated){
      navigate("/login", { replace: true })
    }
    }
    if(isAuthenticated && allow){
      if(userData?.default_role_code==="ADMIN" || userData?.default_role_code==="ADMIN_VIEWER"){
        navigate("/app/dashboard", { replace: true })
       }
       else if(userData.default_role_code==="SALES"){
         navigate("/app/dashboard", { replace: true })
       }
       else if(userData.default_role_code==="OPERATION"){
        navigate("/app/dashboard", { replace: true })
       }
       else if(userData.default_role_code==="INVESTER_CLIENT"){
         navigate("/app/investor-dashboard", { replace: true })
       }
       else if(userData.default_role_code==="CHANNEL_PARTNER"){
         navigate("/app/investor-dashboard", { replace: true })
       }
       }

   
    // if(!allow){
    //   navigate("/login", { replace: true })
    // }
   
    // else if(!isAuthenticated){
    //   navigate("/login", { replace: true })
    // }
  
    
  },[])


  return (
    <Root>
    <div
      ref={ref}
      {...rest}
      className={clsx(classes.root, addClass)}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
    <BackToTop/>
    </Root>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};



const mapStateToProps = state => {    
  return ({
      force: state, // force state from reducer
      isAuthenticated: state.authReducer.auth && state.authReducer.auth.loggedIn,
  })
};

export default connect(mapStateToProps)(Page);