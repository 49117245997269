import axios from "axios";

export const versionStatus = {
    getVersion,
    getDbHealthStatus      
};
 

function getVersion() {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/version`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


function getDbHealthStatus() {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/db_health_status`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

