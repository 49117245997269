/*eslint-disable*/
import React from 'react';
import { styled } from '@mui/material/styles';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ListItem,
} from '@mui/material';

import Icon from '@mui/material/Icon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const PREFIX = 'NavItem';

const classes = {
  item: `${PREFIX}-item`,
  accordion: `${PREFIX}-accordion`,
  accordionSummary: `${PREFIX}-accordionSummary`,
  details: `${PREFIX}-details`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
  title: `${PREFIX}-title`,
  active: `${PREFIX}-active`
};

const StyledListItem = styled(ListItem)((
  {
    theme
  }
) => ({
  [`&.${classes.item}`]: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },

  [`& .${classes.accordion}`]: {
    boxShadow: 'none',
    width: '220px'
  },

  [`& .${classes.accordionSummary}`]: {
    padding: 0,
    height: '45px'
  },

  [`& .${classes.details}`]: {
    display: "flex",
    flexDirection: "column"
  },

  [`& .${classes.button}`]: {
    color: theme.palette.text.secondary,
    fontWeight: theme.fontWeightRegular,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%',
    borderRadius: 0, 
    margin: "0 1rem",
  },

  [`& .${classes.icon}`]: {
    marginRight: theme.spacing(1)
  },

  [`& .${classes.title}`]: {
    marginRight: 'auto'
  },

  [`& .${classes.active}`]: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    paddingLeft: "1rem",
    background: "#203e89",
    marginLeft: "1rem",
    minWidth:'200px',
    textTransform:'none',
    borderRadius: "5rem",
    color: "white"
  }
}));

const NavItem = ({
  className,
  href,
  icon,
  title,
  subMenu,
  ...rest
}) => {


  let location = useLocation();
  let path= location.pathname

  return (
    <StyledListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >

      {subMenu ? (
        <Accordion className={classes.accordion} >
          <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Button
              // activeClassName={classes.active}
              className={path ===href ? classes.active :classes.button}
              component={RouterLink}
              to={href}
            >

              <Icon className={classes.icon}>{icon}</Icon>
              <span className={classes.title}>
                {title}
              </span>
            </Button>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            {subMenu.map((item,index) => (
              <Button
                key={item.href}
                // activeClassName={classes.active}
                className={path ===item.href && index==1 ? classes.active :classes.button}
                component={RouterLink}
                to={item.href}
              >

                <Icon className={classes.icon}>{item.icon}</Icon>
                <span className={classes.title}>
                  {item.title}
                </span>
              </Button>
            ))}
          </AccordionDetails>
        </Accordion>


      ) : (
        <Button
          // activeClassName={classes.active}
          className={path ===href ? classes.active :classes.button}
          component={RouterLink}
          to={href}
        >

          <Icon className={classes.icon}>{icon}</Icon>
          <span className={classes.title}>
            {title}
          </span>
        </Button>
      )}

    </StyledListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
