import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";

import {

  Container,
  TextField,
  Grid

} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import { getLookUpDataService } from "src/_services/lookUp";
import Autocomplete from '@mui/material/Autocomplete';
import loadingSpinner from 'src/images/loading-spinner.gif'
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"

const PREFIX = 'UpdateAssignForm';

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  paddingInput: `${PREFIX}-paddingInput`,
  buttonProgress: `${PREFIX}-buttonProgress`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.paddingInput}`]: {
    padding: "0px 0px",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: '4px'
  }
}));



function UpdateAssignForm(props) {

  const [salesAndOperation, setSalesAndOperation] = React.useState([]);
  const [currentAssign, setCurrentAssign] = React.useState([{ id: "" }]);
  const [loading, setLoading] = React.useState(false)
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)





  useEffect(() => {
    getLookUpDataService
      .getLookUpUser("SALES,OPERATION")
      .then((res) => {
        if (res) {


          if (res.status === 200) {

            let item = res.data.map((items) => {
              return {
                id: items.user_code,
                label: `${items.user_name}`,
              };
            });

            // eslint-disable-next-line array-callback-return
            const currentAssignData = res.data.map((data) => {
              if (`${data.user_name}` === props.assign) {
                return {
                  id: data.user_code,
                  label: `${data.user_name}`,
                };
              }
            }).filter(data => data !== undefined)

            //    console.log("currentAssignData",currentAssignData);

            setCurrentAssign(currentAssignData)
            setSalesAndOperation(item);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }
        }

      })
      .catch((error) => {
        console.log("error");
      });
  }, [])


  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialValues={{
          assign: currentAssign[0],
        }}
        validationSchema={Yup.object().shape({
          assign: Yup.string().required("Required"),
        })}
        onSubmit={(values) => {
          setLoading(true)
          values.assign = values.assign.id

          values.service_order_id = props.service_order_id

          props.formSubmit(values)
        }}
      >
        {({
          errors,

          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Autocomplete
                    id="assign"

                    value={values.assign}
                    options={salesAndOperation}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, value) => {


                      if (value !== null) {
                        setFieldValue("assign", value);
                      } else {
                        setFieldValue("assign", "");
                      }
                    }}
                    // getOptionSelected={(item, current) => item.value == current.value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.assign && errors.assign)}
                        helperText={touched.assign && errors.assign}

                        name="assign"
                        label="Assign *"

                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <ErrorFocus />
              </Grid>


              <Button
                color="primary"
                disabled={isSubmitting}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="contained"
              >
                {loading === false ? "Update" : <><img alt="loader" src={loadingSpinner} className={classes.buttonProgress} /> Update </>}
              </Button>
            </Container>
          </form>
        )}
      </Formik>
      <SessionExpiredModal
        open={openSessionExpiredModal}
      />
    </StyledContainer>
  );
}

export default UpdateAssignForm;
